import React from 'react'
import './ResultsHeader.scss'

const ResultsHeader = (props) => {
  const {children, pagination, title} = props

  return (
    <header className="ResultsHeader">
      <div className="ResultsHeader_actions">
        <h1 className="ResultsHeader_title">{title}</h1>
        {children}
      </div>
      {pagination}
    </header>
  )
}

export {ResultsHeader}
