/**
 * Formatted display display for a Provider by last name, first name and office.
 * All display components are optional.
 * example return if all values are present: 'Sample, Steve / Office of Medicine'
 * @param {Object}    provider
 * @param {string}    [provider.first]
 * @param {string}    [provider.last]
 * @param {string}    [provider.officeName]
 * @returns {string}
 */
const formattedProvider = ({first, last, officeName}): string => {
  const providerDisplayItems: string[] = []
  const providerName: string[] = []
  if (first) providerName.push(first)
  if (last) providerName.push(last)
  if (providerName.length > 0) providerDisplayItems.push(providerName.join(', '))
  if (officeName) providerDisplayItems.push(officeName)

  return providerDisplayItems.join(' / ')
}

export {formattedProvider}
