import React, {useEffect, useState} from 'react'
import {Route, Switch} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import {ThinkingDot} from '@flumehealth/ui-react'
import {getZendeskLocationViews, isZendeskEnv, setClientContext} from '../../../utils'
import {ZendeskBackground} from './support/background/ZendeskBackground'
import {ZendeskModals} from './support/modal/ZendeskModals'
import {ZendeskMemberInfo} from './support/sidebar/ZendeskMemberInfo'
import {ZendeskNavBar} from './support/navBar/ZendeskNavBar'
import {ZendeskNewTicketSidebarMessage} from './support/newTicketSidebar/ZendeskNewTicketSidebarMessage'
import {ZendeskOrganizationSidebar} from './support/organizationSidebar/ZendeskOrganizationSidebar'
import {ZendeskTopBar} from './support/topBar/ZendeskTopBar'
import {ZendeskErrorBoundary} from './common/ZendeskErrorBoundary'
import './Zendesk.scss'

/**
 * Root component for Trestle-based Zendesk apps.
 * Uses the Zendesk Client location for determining which app to display.
 */
const ZendeskApp = () => {
  const [zendeskClient, setZendeskClient] = useState<any | null>(null)
  const [zendeskClientContext, setZendeskClientContext] = useState<any | null>(null)

  useEffect(() => {
    if (isZendeskEnv()) {
      // @ts-ignore
      const client = ZAFClient.init()
      setZendeskClient(client)
      setClientContext(client, setZendeskClientContext)
    }
  }, [])

  const zendeskContextView = (currZendeskClientContext) => {
    if (!currZendeskClientContext) {
      return (
        <div className="fh-Zendesk_placeholder">
          <ThinkingDot />
        </div>
      )
    }

    // `ZendeskAppContext` relates to the `ZendeskAppContext` of the current location
    // `ZendeskAppContext` provides switching between Trestle-based views for a specific Zendesk app location
    // ZendeskApp asks Background for current location information before continuing
    getZendeskLocationViews(zendeskClient)

    switch (zendeskClientContext.location) {
      case 'background':
        return <ZendeskBackground />
      case 'modal':
        // router for modal views
        return <ZendeskModals />
      case 'nav_bar':
        return <ZendeskNavBar />
      case 'new_ticket_sidebar':
        return <ZendeskNewTicketSidebarMessage />
      case 'organization_sidebar':
        return <ZendeskOrganizationSidebar />
      case 'ticket_sidebar':
        return <ZendeskMemberInfo />
      case 'top_bar':
        return <ZendeskTopBar />
      case 'user_sidebar':
        return <ZendeskMemberInfo />
      // TODO - update before release
      default:
        return <h1>Please refresh to continue</h1>
    }
  }

  const currView = zendeskContextView(zendeskClientContext)
  const resetHistory = () => console.log('reset via history')

  return (
    <Switch>
      <Route path="/">
        <ErrorBoundary FallbackComponent={ZendeskErrorBoundary} onReset={() => resetHistory()}>
          <div className="fh-ZendeskApp">{currView}</div>
        </ErrorBoundary>
      </Route>
    </Switch>
  )
}

export {ZendeskApp}
