import React, {useEffect, useMemo, useState} from 'react'
import axios from 'axios'
import {useGlobals} from '../../../context'
import {ZendeskApp} from './ZendeskApp'
import {ZendeskAppContext} from './ZendeskAppContext'
import {ZendeskLogin} from './ZendeskLogin'
import classNames from 'classnames'
import {isZendeskEnv} from '../../../utils'

const Zendesk = () => {
  const {apiHostname, apiProtocol, appHostname, appProtocol} = useGlobals()
  const [isAuthed, setIsAuthed] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)
  const [isWaiting, setIsWaiting] = useState(true)
  const [viewHistory, setViewHistory] = useState<any[]>([])
  const verifyUrl = `${apiProtocol}://${apiHostname}/v1/login`
  const loginUrl = `${verifyUrl}?redirect=${appProtocol}://${appHostname}/return-to-zendesk`
  const pause = 1000 * 0.5

  const zendeskAppContextValues: any = useMemo((): any => ({viewHistory, setViewHistory}), [viewHistory, setViewHistory])

  const verifyAuth = async () => {
    const axiosConfig = {
      headers: {
        Accept: 'application/json'
      },
      withCredentials: true
    }

    setTimeout(() => {
      setIsAvailable(true)
    }, pause)

    await axios
      .get(verifyUrl, axiosConfig)
      .then((response) => {
        // api call has a small but jarring lag
        // make this more palatable with a sort pause
        if (response.status === 204) {
          setIsAuthed(true)
        } else {
          setIsAuthed(false)
          setIsWaiting(false)
        }
      })
      .catch((e) => {
        // TODO – replace with logging service

        // eslint-disable-next-line no-console
        console.log(e)
        // api call has a small but jarring lag
        // make this more palatable with a sort pause
        setIsAuthed(false)
        setIsWaiting(false)
      })
  }

  useEffect(() => {
    verifyAuth()
  }, [])

  const blockClass = classNames({
    'fh-Zendesk': true,
    'fh-Zendesk___development': !isZendeskEnv()
  })

  return (
    <div className={blockClass}>
      {!isAuthed || !isAvailable ? (
        <ZendeskLogin isWaiting={isWaiting} loginURL={loginUrl} />
      ) : (
        <ZendeskAppContext.Provider value={zendeskAppContextValues}>
          <ZendeskApp />
        </ZendeskAppContext.Provider>
      )}
    </div>
  )
}

export {Zendesk}
