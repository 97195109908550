import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {ZendeskMemberClaims} from './ZendeskMemberClaims'
import {ZendeskMemberHippaWarning} from './ZendeskMemberHippaWarning'

/**
 * Root component for all modal Zendesk app instances.
 * Use the openModal() util to call one of the routes defined below.
 */
const ZendeskModals = () => {
  return (
    <>
      <Switch>
        <Route path="/zendesk/member-claims">
          <ZendeskMemberClaims />
        </Route>
        <Route path="/zendesk/hippa-warning">
          <ZendeskMemberHippaWarning />
        </Route>
      </Switch>
    </>
  )
}

export {ZendeskModals}
