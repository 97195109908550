import React from 'react'
import {convertDashedDatetoDate, formatCurrencyI18n} from '@flumehealth/ui-react'
import {AccumulatorNetworksTable} from './AccumulatorNetworksTable'
import {ZendeskAspectGroup} from '../ZendeskAspectGroup'
import {ZendeskError} from '../ZendeskError'

const AccumulatorOverviewYear = (props) => {
  const {accumulatorData} = props

  // ZendeskAspectGroup config attributes
  // title            display title
  // [type]           hook for special cases, value can be anything
  // [format]         shared display and behavior options
  //                  values can be `groupStart`, `groupEnd`, `toggleDisplay`
  // [formattedValue] data formatting function
  const accumulatorTotalsConfig = new Map([
    ['totals.charges', {title: 'Charges', formattedValue: formatCurrencyI18n}],
    ['totals.copay', {title: 'Copay', formattedValue: formatCurrencyI18n}],
    ['totals.coveredExpenses', {title: 'Covered Expenses', formattedValue: formatCurrencyI18n}],
    ['totals.planPaid', {title: 'Plan Paid', formattedValue: formatCurrencyI18n}]
  ])

  try {
    accumulatorData.sort((a, b) => (a.year < b.year ? 1 : -1))

    return accumulatorData.map((planYear) => {
      const planYearAnniversary =
        planYear.planYearAnniversary && typeof planYear.planYearAnniversary === 'number'
          ? convertDashedDatetoDate(planYear.planYearAnniversary.toString())
          : convertDashedDatetoDate(planYear.planYearAnniversary)
      return (
        <div className="fh-ZendeskMemberAccumulator" key={`${planYear.year}–${planYear.product}`}>
          <header className="fh-ZendeskMemberAccumulator_header">
            <h2 className="fh-ZendeskMemberAccumulator_title">{`${planYear.year} – ${planYear.product}`}</h2>
            <div className="fh-ZendeskMemberAccumulator_anniversary">
              <p className="fh-ZendeskMemberAccumulator_anniversaryLabel">Plan Year Anniversary</p>
              <p className="fh-ZendeskMemberAccumulator_anniversaryDate">{planYearAnniversary}</p>
            </div>
          </header>
          <ZendeskAspectGroup config={accumulatorTotalsConfig} data={planYear} />
          <AccumulatorNetworksTable data={planYear} />
        </div>
      )
    })
  } catch (error) {
    // capture and display minimal diagnostic info for API or data errors
    const errorContent = !Array.isArray(accumulatorData) ? accumulatorData : error
    return <ZendeskError errorOutput={[errorContent]} />
  }
}

export {AccumulatorOverviewYear}
