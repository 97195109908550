/* global window */
import React, {useEffect, useState} from 'react'
import {BasicButton, usePageVisibility} from '@flumehealth/ui-react'
import {SpinnerScaleout} from '../../spinners/SpinnerScaleout'
import './ZendeskLogin.scss'

const ZendeskLogin = (props: any) => {
  const {isWaiting, loginURL} = props
  const [sendToAuth, setSendToAuth] = useState(false)
  const refreshPage = () => window.location.reload()
  usePageVisibility(refreshPage, null, sendToAuth)
  const authenticateUser = () => {
    setSendToAuth(true)
  }

  useEffect(() => {
    if (loginURL && sendToAuth) {
      window.open(loginURL, '_blank')
    }
  }, [sendToAuth])

  const authPanel = (
    <>
      <BasicButton title="Log in" clickHandler={authenticateUser} />
      <BasicButton cssClasses={['fh-Zendesk_secondaryButton']} title="Refresh after log in" clickHandler={refreshPage} />
      <p className="fh-ZendeskLoginPage_actionMessage">note: opens Google in new tab</p>
    </>
  )

  // prettier-ignore
  return (
    <div className="fh-ZendeskLoginPage">
      <div className="fh-ZendeskLoginPage_action">
        {isWaiting ? <SpinnerScaleout /> : authPanel}
      </div>
    </div>
  )
}

export {ZendeskLogin}
