import React from 'react'
import {formatPhoneDashes} from '@flumehealth/ui-react'
import {FormatEmpty} from '../display/FormatEmpty'
import './ContactVertical.scss'

/**
 * Vertically formatted contact information block.
 * @param {Object}  props
 * @param {string}  [props.email]
 * @param {string}  [props.phoneHome]
 * @param {string}  [props.phoneMobile]
 * @param {string}  [props.title='Contact']
 */
const ContactVertical = (props) => {
  const {email, phoneHome, phoneMobile, title = 'Contact'} = props

  return (
    <div className="ContactVertical">
      <h2 className="ContactVertical_title">{title}</h2>
      <address className="ContactVertical_address">
        <p className="ContactVertical_element">
          <span className="ContactVertical_label">Home Phone</span>
          <FormatEmpty value={formatPhoneDashes(phoneHome)} />
        </p>
        <p className="ContactVertical_element">
          <span className="ContactVertical_label">Mobile Phone</span>
          <FormatEmpty value={formatPhoneDashes(phoneMobile)} />
        </p>
        <p className="ContactVertical_element">
          <span className="ContactVertical_label">Email</span>
          <FormatEmpty value={email} />
        </p>
      </address>
    </div>
  )
}

export {ContactVertical}
