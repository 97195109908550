import React from 'react'
import './ResultsFooter.scss'

const ResultsFooter = (props) => {
  const {pagination} = props

  return <div className="ResultsFooter">{pagination}</div>
}

export {ResultsFooter}
