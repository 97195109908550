import React from 'react'
import axios, {AxiosRequestConfig} from 'axios'
import get from 'lodash.get'
import {BasicButton} from '@flumehealth/ui-react'
import {useGlobals} from '../../../../../context'
import {useZendeskClient} from '../../hooks/zendesk'
import {getMemberDisplayLinkData, saveNewLink} from '../../utils'
import './ZendeskMemberSearchResults.scss'

const ZendeskMemberSearchResults = (props) => {
  const {apiHostname, apiProtocol} = useGlobals()
  const {locationType, members, setMember, setNewLinkId} = props
  const [zendeskClient] = useZendeskClient()
  const linksBaseUrl = `${apiProtocol}://${apiHostname}/v1/zendesk/links`
  const membersURLBase = `${apiProtocol}://${apiHostname}/v1/members`

  const getInboundRequesterType = async () => {
    const ticketData = await zendeskClient.get('ticket')
    return ticketData
  }

  const setTicketMember = (compoundMemberId, zendeskMemberId, isMemberRequester) => {
    const idComponents = compoundMemberId ? compoundMemberId.split('-') : []
    const memberId = compoundMemberId ? idComponents[0] : ''
    const sequenceNum = compoundMemberId ? Number(idComponents[1]) : ''

    if (memberId) {
      const options: AxiosRequestConfig = {
        method: 'get',
        url: `${membersURLBase}/${memberId}`,
        withCredentials: true
      }
      axios(options).then((response) => {
        const data: any = get(response, 'data', {})
        const person = data.people[sequenceNum]
        if (person.compoundMemberId) {
          setMember(compoundMemberId)
        }

        // set Zendesk new ticket 'Requester' and email 'To' field in ticket body
        // Todo: let's discuss the right behavior here. Disabling temporarily to ensure we don't create messes for CSRs
        if (isMemberRequester && zendeskMemberId) {
          // TODO – set Zendesk ids when available
          zendeskClient.set('ticket.requester', {id: zendeskMemberId})
        }
      })
    }
  }

  const saveLinkCallbackTest = (data) => {
    const newLinkId = get(data, 'id', '')
    setNewLinkId(newLinkId)
  }

  const updateTicketState = (compoundMemberId, zendeskMemberId = null, isRequesterUpdate = false) => {
    if (locationType !== 'ticket_sidebar') {
      setTicketMember(compoundMemberId, null, false)
      return
    }

    setTicketMember(compoundMemberId, zendeskMemberId, isRequesterUpdate)

    getInboundRequesterType().then((ticketData) => {
      if (ticketData) {
        const ticketId = get(ticketData, 'ticket.id', '')
        const linkData = getMemberDisplayLinkData(`${ticketId}`, compoundMemberId)
        saveNewLink(linksBaseUrl, linkData, saveLinkCallbackTest)
      }
    })
  }

  const memberLinks = members.map((member) => {
    // TODO - additionally disable if Zendesk member id unavailable
    const disableSetReq = locationType !== 'ticket_sidebar'
    const zendeskMemberId = get(member, 'systemProvided.zendeskID', null)
    const memberInfo = (
      <>
        {member.compoundMemberId}
        <span> &nbsp; </span>
        {member.last}, {member.first}
      </>
    )

    return (
      <div className="fh-ZendeskMemberSearchResults_result">
        <BasicButton clickHandler={() => updateTicketState(member.compoundMemberId)} cssClasses={['fh-Zendesk_secondaryButton']}>
          {memberInfo}
        </BasicButton>
        <BasicButton clickHandler={() => updateTicketState(member.compoundMemberId, zendeskMemberId, true)} disabled={disableSetReq} cssClasses={['fh-Zendesk_secondaryButton']}>
          <span className="fh-Zendesk_altActionButton">Set Req</span>
        </BasicButton>
      </div>
    )
  })

  return (
    <div className="fh-ZendeskMemberSearchResults">
      <h3 className="fh-Zendesk_sidebarTitle">Search Results</h3>
      {memberLinks}
    </div>
  )
}

export {ZendeskMemberSearchResults}
