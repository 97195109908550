import React, {useEffect, useState} from 'react'
import get from 'lodash.get'
import {BasicButton, ThinkingDot} from '@flumehealth/ui-react'
import {isZendeskEnv, registerZendeskAppInstance} from '../../../../../utils'
import './ZendeskMemberHippaWarning.scss'

/**
 * Display warning information, with an override option, about accessing private data on behalf of a requester. The private data requires HIPAA rights to view.
 * If override is selected by user, a response via Zendesk Client API will be sent to the Zendesk Client that launched this Client.
 */
const ZendeskMemberHippaWarning = () => {
  const [zendeskClient, setZendeskClient] = useState<any>(null)
  const [, setZendeskContext] = useState<any>(null)
  const [, setData] = useState<any>(null)
  const [memberId, setMemberId] = useState<string>('')
  const [viewMember, setViewMember] = useState<string>('')
  const [accessorName, setAccessorName] = useState<string>('')
  const [accesseeName, setAccesseeName] = useState<string>('')
  const [isClosing, setIsClosing] = useState<boolean>(false)
  const [parentClient, setParentClient] = useState<any>(null)

  useEffect(() => {
    if (isZendeskEnv()) {
      try {
        // no Zendesk ZAFClient type
        // @ts-ignore
        const client = ZAFClient.init()
        setZendeskClient(client)

        if (client) {
          client.on('fh_data', (customEvent) => {
            // for better readability
            const getValueOrDefault = (path) => get(customEvent, path, '')

            if (customEvent.action === 'initialData') {
              const spawningClient = client.instance(customEvent.parentInstanceGuid)
              setParentClient(spawningClient)
              setViewMember(getValueOrDefault('options.viewMember'))
              setMemberId(getValueOrDefault('options.memberId'))
              setAccessorName(getValueOrDefault('options.accessorName'))
              setAccesseeName(getValueOrDefault('options.accesseeName'))
            } else if (customEvent.action === 'error') {
              setData(customEvent.data) // keep for now
            } else {
              // TODO - add 3rd party error tooling
              // throw new Error(`Unknown customEvent action ${customEvent.action}`)
            }

            registerZendeskAppInstance(client, setZendeskContext)
          })
        }
      } catch (err) {
        // TODO - add 3rd party error tooling
      }
    }
  }, [])

  const viewWithoutRights = () => {
    if (parentClient) {
      try {
        parentClient.trigger('fh_data', {action: 'hippaOverride', viewMember, memberId})
      } catch (err) {
        // TODO - add 3rd party error tooling
      }
    }
    setIsClosing(true)
    // give APIs a moment to receive and process `hippaOverride` data from trigger above
    setTimeout(() => zendeskClient.invoke('destroy'), 3000)
  }

  const closeModal = () => {
    try {
      zendeskClient.invoke('destroy')
    } catch (err) {
      // TODO - add 3rd party error tooling
    }
  }

  const placeholder = (
    <div className="fh-Zendesk_placeholder">
      <ThinkingDot />
    </div>
  )

  return (
    <div className="fh-ZendeskMemberHippaWarning">
      {!isClosing && accessorName && accesseeName ? (
        <>
          <h1 className="fh-ZendeskMemberHippaWarning_title">HIPPA Rights Access Issue</h1>
          <p className="fh-ZendeskMemberHippaWarning_info">
            {accessorName} does not have HIPAA Rights Access for {accesseeName}.
          </p>
          <BasicButton clickHandler={closeModal} title={'Close'} />
          <BasicButton clickHandler={viewWithoutRights} cssClasses={['u_linkButton']} title={`View ${accesseeName} Without HIPAA Rights Access`} />
        </>
      ) : (
        placeholder
      )}
    </div>
  )
}

export {ZendeskMemberHippaWarning}
