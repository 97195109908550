import React from 'react'

/**
 * Return standard empty item for display. Accepts any type.
 * @param {Object}  props
 * @param {any}     props.value
 */
const FormatEmpty = (props) => {
  const {value} = props

  return value ? value : <span className="u_emptyValue">—</span>
}

export {FormatEmpty}
