import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {FormProvider, useForm} from 'react-hook-form'
import {LeftNavProvider, ModalProvider} from '@flumehealth/ui-react'
import {GlobalsProvider, useGlobals} from './globals-context'

function AppProviders(props) {
  const {children} = props
  const methods = useForm()

  return (
    <GlobalsProvider>
      <Router>
        <FormProvider {...methods}>
          <LeftNavProvider>
            <ModalProvider>{children}</ModalProvider>
          </LeftNavProvider>
        </FormProvider>
      </Router>
    </GlobalsProvider>
  )
}

export {AppProviders, useGlobals}
