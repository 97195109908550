import React, {useContext, useEffect, useState} from 'react'
import cloneDeep from 'lodash.clonedeep'
import get from 'lodash.get'
import classNames from 'classnames'
import {ComposableForm, isArrayItem, isArrayWithItems, ModalContext, setComposableFormValues, useSortableColumn} from '@flumehealth/ui-react'
import {getKeyData} from '../../utils'
import {DeleteItem} from '../actions/DeleteItem'
import {SpinnerScaleout} from '../spinners/SpinnerScaleout'
import './ResultsList.scss'

const ResultsList = (props) => {
  const {actions, baseURL, deleteButtonLabel, defaultSort, deleteTitle, editFormConfig, errorCode, keyLabels, keys, modalSuccessHandler, requestStatus, rows, toggleSortConfig = {}} = props
  const {showModal} = useContext(ModalContext)
  const [rowData, setRowData] = useState(rows)
  const [sortActions, sortConfig, setSortConfig] = useSortableColumn(toggleSortConfig)
  const isDeleteable = isArrayItem(actions, 'delete')
  const isEditable = isArrayItem(actions, 'edit')
  const resultItemClass = classNames({
    ResultsList_item: true,
    ResultsList_item___editable: isEditable
  })

  const setEditModalTitle = (editData, id) => {
    const firstComponentID = get(editData, 'fields[0].formComponentID', '')
    if (firstComponentID === 'formTitle') {
      editData.fields[0].title = `${editData.fields[0].title} – ID ${id}`
    }
  }

  const getFormValues = (row) => {
    const newRow = cloneDeep(row)
    // lift newRow.options values to top level
    const formValues = {...newRow, ...newRow.options}
    delete formValues.options
    return formValues
  }

  const showEditModal = (row) => {
    const editFormConfigWithValues = setComposableFormValues(getFormValues(row), editFormConfig)

    if (isEditable) {
      const editURL = `${baseURL}/${row.id}`
      setEditModalTitle(editFormConfigWithValues, row.id)
      showModal(<ComposableForm action="update" config={editFormConfigWithValues} endpoint={editURL} formSuccessHandler={modalSuccessHandler} />)
    }
  }

  const showDeleteModal = (row) => {
    if (isDeleteable) {
      showModal(<DeleteItem baseURL={baseURL} buttonLabel={deleteButtonLabel} modalSuccessHandler={modalSuccessHandler} rowID={row.id} title={deleteTitle} />)
    }
  }

  const getDisplayRows = (rowsData) => {
    return rowsData.map((row) => {
      const dataCells = keyLabels.map((item) => {
        const dataCell = row[item.key]
        let data: JSX.Element[] = []

        if (keyData[item.key].type === 'object') {
          data = Object.entries(dataCell).map((entry) => {
            const [key, value] = entry
            // eslint-disable-next-line no-nested-ternary
            const displayValue = value === true ? 'Yes' : value === false ? 'No' : value
            return (
              <span className="ResultsList_itemCellData" key={`span-a-${key}`}>
                {key}: {displayValue}
              </span>
            )
          })
        } else if (keyData[item.key].type === 'array') {
          data = dataCell.map((arrayItem) => (
            <span className="ResultsList_itemCellData" key={`span-b-${arrayItem}`}>
              {arrayItem}
            </span>
          ))
        } else {
          // primitives
          data = [
            <span className="ResultsList_itemCellData" key={`span-c-${dataCell}`}>
              {typeof dataCell === 'boolean' ? dataCell.toString() : dataCell}
            </span>
          ]
        }

        return (
          <span className="ResultsList_itemCell" key={`cell-${item.key}`} onClick={() => showEditModal(row)}>
            {data}
          </span>
        )
      })

      const actionEdit = isEditable ? (
        <button className="ResultsList_itemEdit ResultsList_action" onClick={() => showEditModal(row)}>
          Edit
        </button>
      ) : null

      const actionDelete = isDeleteable ? (
        <button className="ResultsList_itemDelete  ResultsList_action" onClick={() => showDeleteModal(row)}>
          Delete
        </button>
      ) : null

      return (
        <li className={resultItemClass} key={`row-${row.id}`}>
          {dataCells}
          <span className="ResultsList_itemCell ResultsList_itemCellActions">
            {actionEdit}
            {actionDelete}
          </span>
        </li>
      )
    })
  }

  const nextSortState = (orderStates: string[], currStatePosition: number): number => {
    return currStatePosition + 1 < orderStates.length ? currStatePosition + 1 : 0
  }

  // click-to-sort column headers
  // return saved order, or progress to next order
  // based on previous toggle click and saved state
  const sortState = (options, sortConfig, key): number => {
    const previousKey = isArrayWithItems(sortConfig.selectionHistory) ? sortConfig.selectionHistory[0] : null
    const sortDirection = sortConfig.sortOrderStates[options.sortOrder]

    // return saved sort direction if not null, otherwise increment to the next sort order
    if (previousKey !== key && sortDirection !== null) {
      return options.sortOrder
    }

    return nextSortState(sortConfig.sortOrderStates, options.sortOrder)
  }

  // click-to-sort column headers
  const toggleSort = (e: React.MouseEvent<HTMLSpanElement>, key: string) => {
    if (!sortConfig.columns) return

    const toggle = e.currentTarget
    const allToggles = toggle.parentElement ? Array.from(toggle.parentElement.children) : []
    const options = sortConfig.columns.get(key)

    if (options) {
      options.sortOrder = sortState(options, sortConfig, key)
      sortConfig.columns.set(key, options)
      sortConfig.selectionHistory.unshift(key)
      setSortConfig({...sortConfig})
    }

    // clean-up display, set new toggle state
    if (toggle) {
      const sortDirection = sortConfig.sortOrderStates[options.sortOrder]
      const toggleIcon = sortDirection === 'asc' ? 'u_iconArrowUp' : sortDirection === 'desc' ? 'u_iconArrowDown' : null

      allToggles.forEach((el) => {
        const label = el.querySelector('.u_toggleLabel')
        if (label) label.classList.remove('u_iconArrowUp', 'u_iconArrowDown')
      })

      if (toggleIcon) {
        const activeLabel = toggle.querySelector('.u_toggleLabel')
        if (activeLabel) activeLabel.classList.add(toggleIcon)
      }
    }
  }

  // use updated hook-based actions for sorting toggle or row data updates from CRUD or filters
  useEffect(() => {
    const key = isArrayWithItems(sortConfig.selectionHistory) ? sortConfig.selectionHistory[0] : null

    if (key) {
      if (sortActions.get(key)) {
        const sortFn = (a, b) => sortActions.get(key)(a[key], b[key])
        setRowData([...rows].sort(sortFn))
      } else if (defaultSort) {
        setRowData(defaultSort([...rows]))
      }
    } else {
      setRowData(rows)
    }
  }, [rows, sortActions])

  const headerItem = (itemKey: string, itemLabel: string) => {
    return (
      <div className="ResultsList_headerItem" key={`header-${itemKey}`} onClick={(e) => toggleSort(e, itemKey)}>
        <span className="ResultsList_headerItemLabel u_toggleLabel">{itemLabel}</span>
      </div>
    )
  }

  const keyData = rowData.length > 0 ? getKeyData(keyLabels, rowData) : {}
  const dataHeaders = keys.map((key, i) => {
    const keyItem = keyLabels[i]
    return keyItem.label ? headerItem(key, keyItem.label) : null
  })
  const additionalHeaders = [headerItem('actions', 'Actions')]
  const headers = [...dataHeaders, ...additionalHeaders]
  const resultRows = getDisplayRows(rowData)

  const noResults = (
    <div className="ResultsList_noResults">
      <p className="ResultsList_noResultsMessage">No results found</p>
    </div>
  )

  const awaitingResponse = (
    <div className="ResultsList_waiting">
      <SpinnerScaleout />
    </div>
  )

  return (
    <div className="ResultsList">
      <div className="ResultsList_headers">{headers}</div>
      {requestStatus === 'request' || errorCode === 403 ? awaitingResponse : resultRows.length > 0 ? <ul className="ResultsList_items">{resultRows}</ul> : noResults}
    </div>
  )
}

export {ResultsList}
