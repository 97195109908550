import React from 'react'
import {PlatformSponsor} from '../../common/relationships/PlatformSponsor'
import {AddressVertical} from '../../common/address/AddressVertical'
import {ContactVertical} from '../../common/contact/ContactVertical'
import './MemberDemographics.scss'

/**
 * Display general demographic information about a member, including address and contact info.
 * @param {Object}    props
 * @param {Object}    props.member          API member data
 * @param {Function}  props.setMember       Hooks set state function for parent
 */
const MemberDemographics = (props) => {
  const {member} = props
  const {clientID, clientName, currentResidentialAddress: address, emailAddress, homePhone, locationName, mobilePhone, platformID, platformName} = member

  return (
    <div className="MemberDemographics">
      <PlatformSponsor clientID={clientID} clientName={clientName} locationName={locationName} platformID={platformID} platformName={platformName} />
      <div className="MemberDemographics_details">
        <AddressVertical
          address1={address.address1}
          address2={address.address2}
          address3={address.address3}
          city={address.city}
          country={address.country}
          state={address.state}
          title="Current Residential Address"
          zipCode={address.postalCode}
        />
        <ContactVertical email={emailAddress} phoneHome={homePhone} phoneMobile={mobilePhone} title="Contact Info" />
      </div>
    </div>
  )
}

export {MemberDemographics}
