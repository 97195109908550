import React, {useEffect, useState} from 'react'
import {isZendeskEnv, registerZendeskAppInstance} from '../../../../../utils'

const ZendeskOrganizationSidebar = () => {
  const [zendeskData, setZendeskData] = useState(null)
  const [zendeskClient, setZendeskClient] = useState<any | null>(null)
  const [zendeskContext, setZendeskContext] = useState<any | null>(null)

  useEffect(() => {
    if (isZendeskEnv()) {
      // @ts-ignore
      const client = ZAFClient.init()
      setZendeskClient(client)

      if (client) {
        client.on('fh_data', (passedData) => {
          setZendeskData(passedData)
        })

        registerZendeskAppInstance(client, setZendeskContext)
      }
    }
  }, [])

  return (
    <div>
      <h1>Zendesk Organization Sidebar</h1>
    </div>
  )
}

export {ZendeskOrganizationSidebar}
