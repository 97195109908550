const isZendeskEnv = () => {
  // @ts-ignore
  return ZAFClient && ZAFClient.init() !== false
}

const sendZendeskAppData = async (client, locationName, data) => {
  const {instances} = await client.get('instances')
  const instancesArr: any[] = instances ? Object.values(instances) : []
  const instance = instancesArr.filter((x) => x.location === locationName)[0] // review, can likely be multiple instances of a location

  try {
    if (instance && instance.instanceGuid) {
      const instanceClient = await client.instance(instance.instanceGuid)
      await instanceClient.trigger('fh_data', data)
    }
  } catch (err) {
    // TODO - add 3rd party error tooling
  }
}

const registerZendeskAppInstance = async (client: any, setAppContext: Function) => {
  const context = await client.context()
  const {instanceGuid, location} = context
  setAppContext(context)
  const data = {
    action: 'register',
    instanceGuid,
    location
  }

  sendZendeskAppData(client, 'background', data)
}

const getZendeskLocationViews = async (client) => {
  const context = await client.context()
  const {instanceGuid, location} = context
  const data = {
    action: 'getLocationViews',
    instanceGuid,
    location
  }

  sendZendeskAppData(client, 'background', data)
}

/**
 * @name setClientContext
 * @kind function
 *
 * Set a Zendesk client's `context` in React state with Hooks useState setting function.
 *
 * @param {Object} client         Zendesk client object
 * @param {Function} setContext   React Hook `useState` setter
 */
const setClientContext = async (client: any, setContext: Function): Promise<any> => {
  try {
    const context = await client.context()
    setContext(context)
  } catch (err) {
    // TODO - add 3rd party error tooling
  }
}

export {getZendeskLocationViews, isZendeskEnv, registerZendeskAppInstance, sendZendeskAppData, setClientContext}
