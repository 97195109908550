import React, {useState} from 'react'
import {BasicButton} from '@flumehealth/ui-react'
import {DeleteItemAction} from './DeleteItemAction'
import './DeleteItem.scss'

const DeleteItem = (props) => {
  const {baseURL, buttonLabel = 'Delete', message = "Sure you'd like to do this? There's no turning back...", modalSuccessHandler, rowID, title} = props
  const [hasDeleteClick, setHasDeleteClick] = useState(false)

  const deleteReport = () => {
    setHasDeleteClick(true)
  }

  return (
    <div className="DeleteItem">
      <h2 className="DeleteItem_header">{title ? `${title} – ID ${rowID}` : `Delete ID ${rowID}`}</h2>
      <p>{message}</p>
      {hasDeleteClick ? <DeleteItemAction baseURL={baseURL} rowID={rowID} successHandler={modalSuccessHandler} /> : <BasicButton clickHandler={deleteReport} label={buttonLabel} />}
    </div>
  )
}

export {DeleteItem}
