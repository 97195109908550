import React from 'react'
import './Breadcrumbs.scss'

/**
 * Basic breadcrumbs display.
 * @param {Object}    props
 * @param {string}    props.currentViewLabel
 * @param {Function}  props.returnToParentHandler
 * @param {string}    props.returnToParentLabel
 */
const Breadcrumbs = (props) => {
  const {currentViewLabel, returnToParentHandler, returnToParentLabel} = props

  return (
    <nav className="Breadcrumbs">
      <button className="Breadcrumbs_button">Members</button>
      <span className="Breadcrumbs_spacer">&gt;</span>
      <button className="Breadcrumbs_button" onClick={returnToParentHandler}>
        {returnToParentLabel}
      </button>
      <span className="Breadcrumbs_spacer">&gt;</span>
      <button className="Breadcrumbs_button">{currentViewLabel}</button>
    </nav>
  )
}

export {Breadcrumbs}
