import React from 'react'
import {convertDashedDatetoDate} from '@flumehealth/ui-react'
import {FormatEmpty} from '../../common/display/FormatEmpty'
import './MemberHeader.scss'

/**
 * Fluid display of a Member's name, ID, birth date and gender.
 * @param {Object}  props
 * @param {string}  [props.birthDate]
 * @param {string}  [props.first]
 * @param {string}  [props.gender]
 * @param {string}  [props.last]
 * @param {string}  [props.memberKey]
 */
const MemberHeader = (props) => {
  const {birthDate, first, gender, last, memberKey} = props
  const formattedDOB = birthDate ? convertDashedDatetoDate(birthDate) : birthDate

  return (
    <div className="MemberHeader">
      <h1 className="MemberHeader_name">
        {last}, {first}
      </h1>
      <div className="MemberHeader_details">
        <p className="MemberHeader_element">
          <span className="MemberHeader_label">Member ID</span>
          <FormatEmpty value={memberKey} />
        </p>
        <p className="MemberHeader_element">
          <span className="MemberHeader_label">DOB</span>
          <FormatEmpty value={formattedDOB} />
        </p>
        <p className="MemberHeader_element">
          <span className="MemberHeader_label">Gender</span>
          <FormatEmpty value={gender} />
        </p>
      </div>
    </div>
  )
}

export {MemberHeader}
