import React, {useEffect, useState} from 'react'
import get from 'lodash.get'
import {encodeQueryData, usePreload} from '@flumehealth/ui-react'
import {useGlobals} from '../../../../context'
import {Breadcrumbs} from '../../common/navigation/Breadcrumbs'
import {MemberClaims} from './MemberClaims'
import {MemberDemographics} from './MemberDemographics'
import {MemberHeader} from './MemberHeader'
import {TabNav} from '../../common/navigation/TabNav'
import './MemberViews.scss'

/**
 * Display details related to a member, including member demographic information and claims.
 * @param {Object}    props
 * @param {Object}    props.member          API member data
 * @param {Function}  props.setMember       Hooks set state function for parent
 */
const MemberViews = (props) => {
  const {apiHostname, apiProtocol} = useGlobals()
  const {member, setMember} = props
  const {birthDate, first, gender, last, memberKey} = member
  // cache first page of claims
  const [memberID, sequenceNum] = memberKey.split('-')
  const queryParams = {memberID, sequenceNum, pageSize: '25'}
  const queryData = encodeQueryData(queryParams)
  const memberClaimsEndpoint: string = `${apiProtocol}://${apiHostname}/v1/claims`
  const [preloadClaimsData, , preloadClaimsStatus] = usePreload(`${memberClaimsEndpoint}${queryData}`)
  const [claimsData, setClaimsData] = useState(null)
  const [preloadNextPageToken, setPreloadNextPageToken] = useState<string>('')
  // view management
  const [selectedView, setSelectedView] = useState<string>('demographics')

  useEffect(() => {
    setClaimsData(preloadClaimsData)
    setPreloadNextPageToken(get(preloadClaimsData, 'nextPageToken', ''))
  }, [preloadClaimsData])

  // reset claims display to pre-loaded claims
  // when a user views a non-claims member view
  useEffect(() => {
    if (selectedView !== 'claims') {
      setClaimsData(preloadClaimsData)
    }
  }, [selectedView])

  const tabs = [
    {action: () => setSelectedView('demographics'), label: 'Profile', view: 'demographics'},
    {action: () => setSelectedView('claims'), label: 'Claims', view: 'claims'}
  ]

  const breadcrumbsByView = {
    claims: <Breadcrumbs currentViewLabel="Member Claims" returnToParentHandler={() => setMember(null)} returnToParentLabel="Member Search Results" />,
    demographics: <Breadcrumbs currentViewLabel="Member Profile" returnToParentHandler={() => setMember(null)} returnToParentLabel="Member Search Results" />
  }

  const contentByView = {
    claims: (
      <MemberClaims
        claimsApi={memberClaimsEndpoint}
        claimsData={claimsData}
        parentIsUpdating={claimsData === null}
        preloadNextPageToken={preloadNextPageToken}
        preloadClaimsStatus={preloadClaimsStatus}
        queryParams={queryParams}
        setClaimsData={setClaimsData}
      />
    ),
    demographics: <MemberDemographics member={member} />
  }

  return (
    <div className="MemberViews">
      {breadcrumbsByView[selectedView]}
      <MemberHeader birthDate={birthDate} first={first} gender={gender} last={last} memberKey={memberKey} />
      <TabNav tabs={tabs} selectedView={selectedView} />
      {contentByView[selectedView]}
    </div>
  )
}

export {MemberViews}
