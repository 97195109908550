import React, {useState} from 'react'
import './ZendeskAuthed.scss'
import {TopNav} from '../../nav/TopNav'
import Checkmark from '../../trestle/img/icon-checkmark-green-circle.svg'

const ZendeskAuthed = () => {
  const [userDisplayName] = useState(null)

  return (
    <>
      <TopNav hideMenu={true} userDisplayName={userDisplayName} />
      <div className="fh-ZendeskAuthed">
        <img src={Checkmark} className="fh-ZendeskAuthed_checkmark" alt="checkmark" />
        <h1 className="fh-ZendeskAuthed_title">
          You've signed in
          <br />
          Close this tab and return to Zendesk
        </h1>
      </div>
    </>
  )
}

export {ZendeskAuthed}
