import React from 'react'
import './NoSearchResults.scss'

const NoSearchResults = (props) => {
  const {message = 'No Search Results'} = props

  return (
    <div className="NoSearchResults">
      <p>{message}</p>
    </div>
  )
}

export {NoSearchResults}
