const ClaimLookupConfig = {
  fields: [
    {
      formComponentID: 'claimNumber',
      formComponentType: 'ClaimNumber',
      placeholder: '###-##########-##',
      validation: {
        required: false
      },
      cssClasses: ['fh-Zendesk-minimal']
    },
    {
      formComponentID: 'submitButton',
      formComponentType: 'SearchIconButton',
      title: '',
      cssClasses: ['fh-Zendesk-SearchIconButton']
    }
  ]
}

export {ClaimLookupConfig}
