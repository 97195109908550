import React from 'react'
import {useAxios} from '@flumehealth/ui-react'

const DeleteItemAction = (props) => {
  const {baseURL, rowID, successHandler} = props
  const deleteURL = `${baseURL}/${rowID}`

  // immediately deletes
  // eslint-disable-next-line no-empty-pattern
  const [] = useAxios({url: deleteURL, method: 'DELETE'}, null, successHandler)

  return <>{null}</>
}

export {DeleteItemAction}
