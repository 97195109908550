import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import get from 'lodash.get'
import {ComposableForm, createDisplayLabel, Dropdown, LeftRail, StatusMessage, useAxios} from '@flumehealth/ui-react'
import {useGlobals} from '../../context'
import {ResultsHeader} from '../results/ResultsHeader'
import {LinkList} from '../nav/LinkList'
import './ReportsRunPage.scss'

// test form configs
// replace `formConfig` value with formConfigTest in ComposableForm component below
// import formConfigTest from '../../data/forms/runReportsFundingNotificationRX.json'

const ReportsRunPage = () => {
  const {apiHostname, apiProtocol, errors} = useGlobals()
  const methods = useForm()
  const reportsURL = `${apiProtocol}://${apiHostname}/v1/reports`
  const [componentStatus, setComponentStatus] = useState(null)
  const [reportForms, , apiError] = useAxios({url: reportsURL}, componentStatus)
  const [reportType, setReportType] = useState(null)
  const [isNewReportType, setIsNewReportType] = useState(false)
  const [statusMessageVisible, setStatusMessageVisible] = useState(false)
  const [formConfig, setFormConfig] = useState(null)
  const [dropdownOptions, setDropdownOptions] = useState([])
  const apiErrorCode = get(apiError, 'status', null)
  const submissionMessage: any = statusMessageVisible ? <StatusMessage message="Your changes have been saved" fadeAfter={3000} setVisibility={setStatusMessageVisible} /> : null
  const altReturnFormat = {
    arrayContainerName: 'options',
    keyLabel: 'formComponentID',
    valueLabel: 'value'
  }
  const pages = [
    {link: '/reports', label: 'Report Options Groups'},
    {link: '/run-reports', label: 'Run Reports'}
  ]

  const reportTypeLabel = (type) => {
    const displayLabel = createDisplayLabel(type)
    const labelWords = displayLabel.split(' ')
    const lastWord = labelWords.pop()

    // by default `createDisplayLabel` outputs title case
    // support capitalized abbreviations like `MM` and `RX`
    if (lastWord === 'Mm' || lastWord === 'Rx') {
      labelWords.push(lastWord.toUpperCase())
    } else {
      labelWords.push(lastWord)
    }

    return labelWords.join(' ')
  }

  const errorMessage: any = apiError ? (
    <StatusMessage
      message={apiErrorCode && errors[apiErrorCode] ? errors[apiErrorCode] : `Please refresh your browser to continue`}
      fadeAfter={10000}
      setVisibility={setStatusMessageVisible}
      status="error"
    />
  ) : null

  if (apiErrorCode === 403 && componentStatus !== 403) {
    setComponentStatus(apiErrorCode)
  }

  const successHandler = () => {
    // cleanup prior operations for fast user actions
    if (statusMessageVisible) {
      setStatusMessageVisible(false)
    }
    setStatusMessageVisible(true)
  }

  const updateReportType = (e: Event) => {
    const value = get(e, 'target.value')

    if (reportForms) {
      const apiReports = get(reportForms, 'reports', [])
      const selectedReport = apiReports.filter((item) => item.type === value)[0]
      const runForm = get(selectedReport, 'runForm', null)

      setReportType(value)
      setFormConfig(runForm)
      setIsNewReportType(true)
    }
  }

  // dynamic report type options
  // generate dropdown from reports api data
  useEffect(() => {
    if (reportForms) {
      const apiReports = get(reportForms, 'reports', null)

      if (apiReports) {
        const defaultOption = {title: 'Report Type', value: ''}
        const types = apiReports.map((item) => item.type).sort()
        const options = types.map((item) => ({title: reportTypeLabel(item), value: item}))
        // add default
        options.unshift(defaultOption)
        setDropdownOptions(options)
      }
    }
  }, [reportForms])

  // setup ComposableForm release when swapping form types
  useEffect(() => {
    setIsNewReportType(false)
  }, [formConfig])

  // release ComposableForm when swapping form types
  const runURL = `${reportsURL}/${reportType}/run`
  const composableRunForm =
    formConfig !== null && !isNewReportType ? (
      <ComposableForm action="create" altReturnFormat={altReturnFormat} config={formConfig} endpoint={runURL} formSuccessHandler={successHandler} />
    ) : (
      <div className="ReportsRunPage_placeholder" />
    )

  return (
    <div className="ReportsRunPage">
      {errorMessage || submissionMessage}
      <LeftRail>
        <LinkList links={pages} title="Reports" />
      </LeftRail>
      <ResultsHeader title="Run Reports" />
      <Dropdown changeHandler={(e) => updateReportType(e)} name="reportType" passedContext={methods} options={dropdownOptions} />
      {composableRunForm}
    </div>
  )
}

export {ReportsRunPage}
