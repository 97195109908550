import React from 'react'
import {convertDashedDatetoDate, EllipsisText} from '@flumehealth/ui-react'
import './MembersSearchResultsItem.scss'

const MembersSearchResultsItem = (props) => {
  const {memberData = {}, viewMember} = props
  const {birthDate, clientID, clientName, first, last, memberKey} = memberData

  const formatMissingData = (str: string): string | JSX.Element => {
    return str ? str : <span className="MembersSearchResultsItem_missingValue">—</span>
  }

  const memberID = formatMissingData(memberKey)
  const memberName = `${formatMissingData(last)}, ${formatMissingData(first)}`
  const memberDob = birthDate ? convertDashedDatetoDate(birthDate) : formatMissingData(birthDate)
  const sponsor = `${formatMissingData(clientID)} - ${formatMissingData(clientName)}`
  const platform = formatMissingData('')

  return (
    <tr className="MembersSearchResultsItem" onClick={() => viewMember(memberData)}>
      <td className="MembersSearchResultsItem_id">{memberID}</td>
      <td className="MembersSearchResultsItem_name">
        <EllipsisText>{memberName}</EllipsisText>
      </td>
      <td className="MembersSearchResultsItem_dob">{memberDob}</td>
      <td className="MembersSearchResultsItem_sponsor">
        <EllipsisText>{sponsor}</EllipsisText>
      </td>
      <td className="MembersSearchResultsItem_platform">{platform}</td>
    </tr>
  )
}

export {MembersSearchResultsItem}
