import React from 'react'
import {FormatEmpty} from '../display/FormatEmpty'
import './PlatformSponsor.scss'

/**
 * Display block for Client and Platform details.
 * Format empty values.
 * @param {Object}  props
 * @param {string}  [props.clientID]
 * @param {string}  [props.clientName]
 * @param {string}  [props.locationName]
 * @param {string}  [props.platformID]
 * @param {string}  [props.platformName]
 */
const PlatformSponsor = (props) => {
  const {clientID, clientName, locationName, platformID, platformName} = props

  return (
    <div className="PlatformSponsor">
      <p className="PlatformSponsor_element">
        <span className="PlatformSponsor_label">Platform</span>
        <FormatEmpty value={platformID} /> - <FormatEmpty value={platformName} />
      </p>
      <p className="PlatformSponsor_element">
        <span className="PlatformSponsor_label">Sponsor</span>
        <FormatEmpty value={clientID} /> - <FormatEmpty value={clientName} />
      </p>
      <p className="PlatformSponsor_element">
        <span className="PlatformSponsor_label">Sponsor Location</span>
        <FormatEmpty value={locationName} />
      </p>
    </div>
  )
}

export {PlatformSponsor}
