import React from 'react'
import {ComposableForm, LeftRail, LinkList} from '@flumehealth/ui-react'
import {componentsByTypeReferenceConfig} from '../../data/forms/componentsByTypeReferenceConfig'
import './ComponentsByTypeReferencePage.scss'

const ComponentsByTypeReferencePage = () => {
  const pages = [{link: '/reference-components', label: 'Form Components by Type'}]

  return (
    <div className="ComponentsByTypeReferencePage">
      <LeftRail></LeftRail>
      <ComposableForm action="create" config={componentsByTypeReferenceConfig} watchForm={false} />
    </div>
  )
}

export {ComponentsByTypeReferencePage}
