const extractHostname = (url) => {
  // remove protocol (http, ftp, etc) then get hostname
  const hostWithPorts = url.indexOf('//') > -1 ? url.split('/')[2] : url.split('/')[0]

  // remove port number and '?'
  const [hostname] = hostWithPorts.split(/[:?]/)

  return hostname
}

export {extractHostname}
