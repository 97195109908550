import React from 'react'
import {ZendeskError} from './ZendeskError'
import {ZendeskErrorUnauthorized} from './ZendeskErrorUnauthorized'

const ZendeskErrorBoundary = (props) => {
  const {children, error, errorOutput, message, resetErrorBoundary} = props
  const is403 = error ? error.message === '403' : /403/.test(errorOutput)
  const dummy403ServerMessage = 'You do not have access to this data.'
  
  return is403 ? (
    <ZendeskErrorUnauthorized message={dummy403ServerMessage} />
  ) : (
    <ZendeskError children={children} error={error} errorOutput={errorOutput} message={message} resetErrorBoundary={resetErrorBoundary} />
  )
}

export {ZendeskErrorBoundary}
