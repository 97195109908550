import React from 'react'
import get from 'lodash.get'
import {convertDashedDatetoDate} from '@flumehealth/ui-react'
import {ZendeskAspectGroup} from '../ZendeskAspectGroup'
import './MemberCoverage.scss'

const MemberCoverage = (props) => {
  const {data} = props
  const eligibilitySince = get(data, 'eligibilitySince', null)
  const eligibilityThrough = get(data, 'eligibilityUntil', null)
  const formattedSense = eligibilitySince ? convertDashedDatetoDate(eligibilitySince) : ''
  const formattedThrough = eligibilityThrough ? convertDashedDatetoDate(eligibilityThrough) : ''

  // ZendeskAspectGroup config attributes
  // title            display title
  // [type]           hook for special cases, value can be anything
  // [format]         shared display and behavior options
  //                  values can be `groupStart`, `groupEnd`, `toggleDisplay`
  // [formattedValue] data formatting function
  const coverageDisplayConfig = new Map([
    ['planName', {title: 'Plan Name'}],
    ['planId', {title: 'Plan Id'}],
    ['product', {title: 'Product'}],
    ['coverageLevel', {title: 'Coverage Level'}],
    ['coverageStatus', {title: 'Coverage Status', format: 'groupEnd'}]
  ])

  return (
    <div className="fh-MemberCoverage">
      <h3 className="fh-MemberCoverage_title">{`${formattedSense} – ${formattedThrough}`}</h3>
      <ZendeskAspectGroup config={coverageDisplayConfig} data={data} />
    </div>
  )
}

export {MemberCoverage}
