import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {isZendeskEnv} from './utils'
import './App.scss'
import {Trestle} from './components/trestle/Trestle'
import {Zendesk} from './components/embed/zendesk/Zendesk'

const App = () => {
  return (
    <Switch>
      <Route path="/">{isZendeskEnv() ? <Zendesk /> : <Trestle />}</Route>
    </Switch>
  )
}

export default App
