const memberLookupConfig = {
  fields: [
    {
      formComponentID: 'firstName',
      formComponentType: 'Text',
      title: 'First Name',
      cssClasses: ['u_fullWidth']
    },
    {
      formComponentID: 'lastName',
      formComponentType: 'Text',
      title: 'Last Name',
      cssClasses: ['u_fullWidth']
    },
    {
      formComponentID: 'memberID',
      formComponentType: 'Text',
      title: 'Member ID',
      cssClasses: ['u_fullWidth']
    },
    {
      formComponentID: 'clientID',
      formComponentType: 'Text',
      title: 'Client ID',
      cssClasses: ['u_fullWidth']
    },
    {
      formComponentID: 'birthDate',
      formComponentType: 'MMDDYYYY',
      title: 'Birth Date',
      cssClasses: ['u_fullWidth']
    },
    {
      formComponentID: 'ssn',
      formComponentType: 'Text',
      title: 'SSN',
      cssClasses: ['u_fullWidth']
    },
    {
      formComponentID: 'submitButton',
      formComponentType: 'SubmitButton',
      title: 'Search'
    }
  ]
}

export {memberLookupConfig}
