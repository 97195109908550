import React, {useEffect, useState} from 'react'
import axios, {AxiosRequestConfig} from 'axios'
import get from 'lodash.get'
import {BasicButton, encodeQueryData, useAxios} from '@flumehealth/ui-react'
import {useGlobals} from '../../../../context'
import {ZendeskLinkedItem} from './ZendeskLinkedItem'
import './ZendeskLinkedItemGroup.scss'

// get link retrieval info from parent
// will need some sort of config for `type` `direction` and `id`
// all items should be in the `from` mode, but will need to organize
// should likely highlight the focal ID, which can be either `from` or `to`
// likely need to make requests for each type for full lookup
// lookup happens on init

const ZendeskLinkedItemGroup = (props) => {
  const {apiHostname, apiProtocol} = useGlobals()
  const {hasNewLink = false, id, linksDirection = 'either', linksType = 'ticket', locationType, refreshResultsForId, setHasNewLink, setMember, showSearch, title, zendeskClient, zendeskContext} = props
  // TODO - use globals `apiHostname` value instead of hard coded prod
  const linksBaseUrl = `${apiProtocol}://${apiHostname}/v1/zendesk/links`
  const queryData = encodeQueryData({id: id, type: linksType, direction: linksDirection})
  const [linksUrl, setLinksUrl] = useState('')
  const [requestState, setRequestState] = useState<null | string>('hold')
  const [ticketLinks, setTicketLinks, ticketLinkErrors, ticketLinkRequestStatus] = useAxios({url: linksUrl}, requestState)
  const zendeskAppLocation = get(zendeskContext, 'location', '')

  const getLinks = () => {
    if (zendeskClient) {
      if (locationType === 'user_sidebar') {
        zendeskClient
          .get('user')
          .then((user) => {
            const externalId = id
            const queryData = encodeQueryData({id: externalId, type: 'user', direction: linksDirection})
            setLinksUrl(`${linksBaseUrl}${queryData}`)
            setTicketLinks(null)
            setRequestState(null)
          })
          .catch((err) => {
            // TODO - add 3rd party error tooling
          })
      } else if (locationType === 'ticket_sidebar') {
        zendeskClient
          .get('ticket')
          .then((ticket) => {
            const ticketId = get(ticket, 'ticket.id', '')
            const queryData = encodeQueryData({id: ticketId, type: linksType, direction: linksDirection})
            setLinksUrl(`${linksBaseUrl}${queryData}`)
            setTicketLinks(null)
            setRequestState(null)
          })
          .catch((err) => {
            // TODO - add 3rd party error tooling
          })
      }
    }
  }

  useEffect(() => {
    getLinks()
  }, [])

  useEffect(() => {
    getLinks()
  }, [refreshResultsForId])

  const saveNewLink = (ticketData) => {
    const requestConfig: AxiosRequestConfig = {
      method: 'post',
      // TODO - replace url after testing
      url: linksBaseUrl,
      data: ticketData,
      withCredentials: true
    }

    // TODO - api call
    axios(requestConfig)
      .then((response) => {
        // refresh links display
        setTicketLinks(null)
        setRequestState(null)
      })
      .catch((err) => {
        // TODO - add 3rd party error tooling
      })
  }

  const getNewLinkData = (ticket) => {
    return {
      from: {
        id: `${get(ticket, 'ticket.id', '')}`,
        type: 'ticket'
      },
      note: '',
      source: 'memberDisplay',
      to: {
        id: id,
        type: 'user'
      }
    }
  }

  useEffect(() => {
    if (zendeskAppLocation === 'ticket_sidebar' && hasNewLink && zendeskClient) {
      setHasNewLink(false)
      const zendeskType = locationType === 'user_sidebar' ? 'user' : 'ticket'
      zendeskClient
        .get(zendeskType)
        .then((ticket) => {
          const ticketId = get(ticket, 'ticket.id', '')
          const ticketRequesterExternalId = get(ticket, 'ticket.requester.externalId', '')
          const ticketData = getNewLinkData(ticket)
          const queryData = encodeQueryData({id: ticketId, type: linksType, direction: linksDirection})
          setLinksUrl(`${linksBaseUrl}${queryData}`)
          // if compound member id does not match ticket requester's id, then save new link

          if (ticketRequesterExternalId !== id) {
            saveNewLink(ticketData)
          }
        })
        .catch((err) => {
          // TODO - add 3rd party error tooling
        })
    }
  }, [hasNewLink])

  const links = get(ticketLinks, 'links', [])

  // keeping it simple for launch
  // in the future, we will likely have many types of links
  const getUserAndTicket = (linkData) => {
    const toType = get(linkData, 'to.type', '')
    const ticket = toType === 'ticket' ? get(linkData, 'to', {}) : get(linkData, 'from', {})
    const user = toType === 'user' ? get(linkData, 'to', {}) : get(linkData, 'from', {})
    return [user, ticket]
  }

  const getTitle = (location, user, ticket) => {
    const obj = location === 'ticket_sidebar' ? user : ticket
    return get(obj, 'systemProvided.title', '')
  }

  const linkedItems =
    links.length === 0
      ? [<p className="fh-ZendeskLinkedItemGroup_noResults">None</p>]
      : links.map((linkItem) => {
          const [user, ticket] = getUserAndTicket(linkItem)
          const title = getTitle(zendeskAppLocation, user, ticket)

          const clickHandler = (compoundMemberId) => {
            if (zendeskAppLocation === 'ticket_sidebar') {
              setMember(user.id)
            } else if (zendeskAppLocation === 'user_sidebar') {
              try {
                zendeskClient.invoke('routeTo', 'ticket', ticket.id)
              } catch (err) {
                // TODO - add 3rd party error tooling
              }
            }
          }
          return <ZendeskLinkedItem clickHandler={() => clickHandler(clickHandler)} item={linkItem} title={title} />
        })

  return (
    <div className="fh-ZendeskLinkedItemGroup">
      <h2 className="fh-ZendeskLinkedItemGroup_title">{title}</h2>
      {linkedItems}
      <div>
        <BasicButton clickHandler={() => showSearch()} disabled={typeof showSearch !== 'function'} label="Search Members" />
      </div>
    </div>
  )
}

export {ZendeskLinkedItemGroup}
