import React from 'react'
import {ThinkingDot} from '@flumehealth/ui-react'
import {MembersSearchResultsItem} from './MembersSearchResultsItem'
import {NoSearchResults} from '../../common/search/NoSearchResults'
import './MembersSearchResults.scss'

const MembersSearchResults = (props) => {
  const {isUpdating, searchResultsData, viewMember} = props
  const formattedResults = searchResultsData !== null ? searchResultsData.map((memberData) => <MembersSearchResultsItem memberData={memberData} viewMember={viewMember} />) : null

  const getContent = () => {
    switch (true) {
      case isUpdating:
        return <ThinkingDot />
      case Array.isArray(searchResultsData) && searchResultsData.length === 0:
        return <NoSearchResults />
      default:
        return null
    }
  }

  return (
    <div className="MembersSearchResults">
      <table className="MembersSearchResults_table">
        <thead>
          <tr>
            <th className="MembersSearchResults_id">Member ID</th>
            <th className="MembersSearchResults_name">Name</th>
            <th className="MembersSearchResults_dob">DOB</th>
            <th className="MembersSearchResults_sponsor">Sponsor</th>
            <th className="MembersSearchResults_platform">Platform</th>
          </tr>
        </thead>
        <tbody>{isUpdating ? null : formattedResults}</tbody>
      </table>
      {getContent()}
    </div>
  )
}

export {MembersSearchResults}
