import React from 'react'
import get from 'lodash.get'
import {ThinkingDot} from '@flumehealth/ui-react'
import {NoSearchResults} from '../../common/search/NoSearchResults'
import {MemberClaimsResultsItem} from './MemberClaimsResultsItem'
import './MemberClaimsResults.scss'

const MemberClaimsResults = (props) => {
  const {claimsData, isUpdating, claimsRequestStatus} = props
  const claims = get(claimsData, 'claims', [])

  const placeholder = (
    <tr className="MemberClaimsResults_placeholder">
      <td colSpan={7}>
        <ThinkingDot />
      </td>
    </tr>
  )

  const noResults = (
    <tr className="MemberClaimsResults_noResults">
      <td className="MemberClaimsResults_noResultsMessage" colSpan={7}>
        <NoSearchResults message="No Claims for Member" />
      </td>
    </tr>
  )

  const errorMessage = (
    <tr className="MemberClaimsResults_noResults">
      <td className="MemberClaimsResults_noResultsMessage" colSpan={7}>
        <NoSearchResults message="There was a problem with your claims request" />
      </td>
    </tr>
  )

  const claimsResults = claims.length === 0 ? noResults : claims.map((claim) => <MemberClaimsResultsItem claim={claim} />)
  const formattedResults = claimsRequestStatus === 'error' ? errorMessage : claimsData === null ? null : claimsResults

  return (
    <div className="MemberClaimsResults">
      <table className="MemberClaimsResults_table">
        <thead>
          <tr>
            <th className="MemberClaimsResults_incurred">Incurred</th>
            <th className="MemberClaimsResults_payment">Payment</th>
            <th className="MemberClaimsResults_id">ID</th>
            <th className="MemberClaimsResults_type">Type</th>
            <th className="MemberClaimsResults_charge">Charge</th>
            <th className="MemberClaimsResults_provider">Provider</th>
            <th className="MemberClaimsResults_status">Status</th>
          </tr>
        </thead>
        <tbody>{isUpdating ? placeholder : formattedResults}</tbody>
      </table>
    </div>
  )
}

export {MemberClaimsResults}
