import React from 'react'
import FlumeHealthLogoGreen from './img/flume-health-logo.svg'
import FlumeTrestleLogo from './img/flume-trestle-logo.svg'
import TallTrestleSVG from './img/tall-trestle.svg'

const LogoFlumeHealth = () => <img src={FlumeHealthLogoGreen} className="Logo_flumeHealth" alt="Flume Health" />
const LogoFlumeTrestle = () => <img src={FlumeTrestleLogo} className="Logo_flumeTrestle" alt="Flume Health Trestle" />
const LogoTallTrestle = () => <img src={TallTrestleSVG} className="Logo_trestle" alt="Flume Health Trestle" />

export {LogoFlumeHealth, LogoFlumeTrestle, LogoTallTrestle}
