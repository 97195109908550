import React from 'react'
import get from 'lodash.get'
import {BasicButton} from '@flumehealth/ui-react'
import './ZendeskLinkedItem.scss'

const ZendeskLinkedItem = (props) => {
  const {clickHandler, item, title} = props

  const getDisplayType = (itemType) => {
    switch (itemType.toLowerCase()) {
      case 'facility':
        return 'F'
      case 'member':
        return 'M'
      case 'provider':
        return 'P'
      case 'ticket':
        return 'T'
      default:
        return '?'
    }
  }

  const getRelationshipDisplay = (item) => {
    const fromId = get(item, 'from.id', '')
    const fromType = get(item, 'from.type', '')
    const toId = get(item, 'to.id', '')
    const toType = get(item, 'to.type', '')

    // TODO - add types back at some point in the future
    return (
      <>
        {/*{getDisplayType(fromType)}*/}
        {/*<span className="fh-ZendeskLinkedItem_type">:</span>*/}
        {`${fromId} > `}
        {/*{getDisplayType(toType)}*/}
        {/*<span className="fh-ZendeskLinkedItem_type">:</span>*/}
        {`${toId}`}
      </>
    )
  }

  const relationship = getRelationshipDisplay(item)

  return (
    <BasicButton clickHandler={clickHandler} key={`key-${item.id}`}>
      <span className="fh-ZendeskLinkedItem_relationship">{relationship}</span>
      <span className="fh-ZendeskLinkedItem_info">{title}</span>
    </BasicButton>
  )
}

export {ZendeskLinkedItem}
