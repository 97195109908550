/* global window */
import React from 'react'
import {BasicButton} from '@flumehealth/ui-react'
import {BlobOrange} from '../branding/Blobs'
import {LogoFlumeHealth, LogoTallTrestle} from '../branding/Logo'
import {SpinnerScaleout} from '../spinners/SpinnerScaleout'
import './TrestleLogin.scss'

const TrestleLogin = (props: any) => {
  const {isWaiting, loginURL} = props
  const authenticateUser = () => {
    if (loginURL) {
      window.location.href = loginURL
    }
  }

  const loginButtonAndPlaceholder = isWaiting ? <SpinnerScaleout /> : <BasicButton title="Login to Trestle" clickHandler={authenticateUser} />

  // prettier-ignore
  return (
    <div className="TrestleLogin" onClick={authenticateUser}>
      <header className="TrestleLogin_header">
        <LogoFlumeHealth />
      </header>
      <div className="TrestleLogin_action">
        {loginButtonAndPlaceholder}
      </div>
      <div className="TrestleLogin_ambiance">
        <BlobOrange />
      </div>
      <div className="TrestleLogin_trestleLogo">
        <LogoTallTrestle />
      </div>
    </div>
  )
}

export {TrestleLogin}
