import React, {useEffect, useState} from 'react'
import {isZendeskEnv, sendZendeskAppData} from '../../../../../utils'

const ZendeskBackground = () => {
  const [appInstances, setAppInstances] = useState(new Map())
  const [storedData, setStoredData] = useState(new Map())
  const [appQueue, setAppQueue] = useState([])
  const [zendeskClient, setZendeskClient] = useState<any | null>(null)
  const [zendeskContext, setZendeskContext] = useState<any | null>(null)

  // when registered, create a new client from passed context and save to appInstances
  // when using a stored app instance client, need to test before taking action
  // background should be able to trigger one app, multiple apps or all apps
  // background should be able save/cache data from app instances

  // TODO - move to utils
  const setMap = (map: Map<string, any>, item: any, setFunc: Function): void => {
    setFunc(new Map(map.set(item.key, item.value)))
  }

  useEffect(() => {
    if (isZendeskEnv()) {
      // @ts-ignore
      const client = ZAFClient.init()
      setZendeskClient(client)

      client.on('ticket.saved', (data) => {
        // console.log('>>> background | ticket.saved | data', JSON.stringify(data, null, 2))
      })

      // add TS interface for enforcing contracts
      client.on('fh_data', (passedData) => {
        if (passedData.action === 'register') {
          const {instanceGuid, location} = passedData
          const instanceDetails = {
            instanceGuid,
            location
          }
          if (!appInstances.has(instanceGuid)) {
            setMap(appInstances, {key: instanceGuid, value: instanceDetails}, setAppInstances)
          }
          // each Zendesk `location` can have many `locationView` options
          //
        } else if (passedData.action === 'getLocationViews') {
          const {instanceGuid, location} = passedData
          const callingClient = client.instance(instanceGuid)
          const data = {
            message: 'response from Background'
          }
          sendZendeskAppData(callingClient, location, data)
          // console.log(`getLocationViews instanceGuid ${instanceGuid} location ${location}`)
        } else if (passedData.action === 'setLocationView') {
          const {location, viewName} = passedData
        } else if (passedData.action === 'setStoredData') {
          const {storedDataName, storedDataValue} = passedData
          // console.log(`background location / action: seStoredData – storedDataName ${storedDataName}, storedDataValue  ${storedDataValue}`)
          setMap(storedData, {key: storedDataName, value: storedDataValue}, setStoredData)
        } else {
          // no action
          // console.log(`ZendeskBackground on 'fh_data' passedData`, passedData)
        }
      })
    }
  }, [])

  useEffect(() => {
    // TK – updates for app instance registry
  }, [appInstances])

  return <></>
}

export {ZendeskBackground}
