import React from 'react'
import './TabNav.scss'

/**
 * Collection of horizontally oriented tabs.
 * @param {Object}    props
 * @param {Object[]}  props.tabs      Each tab object includes a string label and function action
 */
const TabNav = (props) => {
  const {tabs, selectedView} = props

  const tabButtons = tabs.map((tab, i) => {
    const buttonClasses = ['TabNav_button']
    if (selectedView === tab.view) buttonClasses.push('TabNav_button___selected')

    return (
      <button className={buttonClasses.join(' ')} onClick={() => tab.action()}>
        {tab.label}
      </button>
    )
  })

  return <nav className="TabNav">{tabButtons}</nav>
}

export {TabNav}
