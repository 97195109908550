import React, {useState} from 'react'
import get from 'lodash.get'
import classNames from 'classnames'
import {BasicButton, convertDashedDatetoDate, formatCurrencyI18n, formatWithWarningsTIN} from '@flumehealth/ui-react'
import {formatDateTimeEasternWithFallback} from '../../../../utils'
import './ZendeskMemberClaimRow.scss'

const ZendeskMemberClaimRow = (props) => {
  const {claim} = props
  const notes = get(claim, 'claimNotes', [])
  const [isOpen, setIsOpen] = useState(false)
  const [showNotes, setShowNotes] = useState(false)

  const blockClass = classNames({
    'fh-ZendeskMemberClaimRow': true,
    'fh-ZendeskMemberClaimRow___open': isOpen
  })

  const handleDetailsToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleNotesToggle = () => {
    setShowNotes(!showNotes)
  }

  const providerAndOffice = (claim) => {
    const provider = claim.provider.first || claim.provider.last
    return `${provider.trim()}${claim.provider.officeName.trim()}`
  }

  const formatProviderAndOffice = (claim) => {
    const firstName = claim.provider.first || ''
    const lastName = claim.provider.last || ''
    const officeName = claim.provider.officeName || ''
    const provider = firstName && lastName ? `${firstName} ${lastName}` : lastName ? lastName : '—'
    const office = officeName ? officeName : '—'
    return `${provider} / ${office}`
  }

  const formatEmpty = (baseClass: string | number, val: any) => {
    const sanitizedVal = typeof val === 'string' ? val.trim() : val
    const statusClass = sanitizedVal || sanitizedVal === 0 ? '' : ` u_negate`
    return `${baseClass}${statusClass}`
  }

  const cleanLink = (str: string) => (str ? str.replace(/(^<|>$)*/g, '') : '')
  const notesCount = notes.length === 1 ? `${notes.length} Note` : `${notes.length} Notes`

  const claimSummary = (
    <div className="fh-ZendeskMemberClaimRow_claimSummary" key={`${claim.claimNumber}-summary`}>
      <span className="u_preserveLine">{convertDashedDatetoDate(claim.dates.incurred)}</span>
      <span className="u_preserveLine">
        {claim.claimNumber} / {claim.product}
      </span>
      <span className="fh-ZendeskMemberClaimRow_claimCharge">{formatCurrencyI18n(claim.totals.charge)}</span>
      <span className="fh-ZendeskMemberClaimRow_claimStatus">{claim.claimStatus}</span>
      <span>{formatProviderAndOffice(claim)}</span>
      <div className="fh-ZendeskMemberClaimRow_claimActions">
        <BasicButton className="fh-Zendesk_toggleDetails" clickHandler={handleDetailsToggle} label={isOpen ? 'Hide Details' : 'Details'} />
        <BasicButton className="fh-Zendesk_toggleDetails" clickHandler={handleNotesToggle} disabled={notes.length === 0} label={showNotes ? 'Hide Notes' : notesCount} />
        {claim.links.claim ? (
          <a href={claim.links.claim} target="_blank" rel="noopener noreferrer">
            JV Claim
          </a>
        ) : (
          <BasicButton className="fh-Zendesk_toggleDetails" disabled={true}>
            JV Claim
          </BasicButton>
        )}
      </div>
    </div>
  )

  const claimDetails = (
    <div className="fh-ZendeskMemberClaimRow_claimDetails" key={`${claim.claimNumber}-details`}>
      <h2 className="fh-ZendeskMemberClaimRow_extrasHeader">Details</h2>
      <div>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.dates.incurred)}>Incurred</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo u_preserveLine">{convertDashedDatetoDate(claim.dates.incurred)}</span>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.dates.received)}>Received</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo u_preserveLine">{convertDashedDatetoDate(claim.dates.received)}</span>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.dates.cleanClaim)}>Clean</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo u_preserveLine">{convertDashedDatetoDate(claim.dates.cleanClaim)}</span>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.dates.processed)}>Processed</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo u_preserveLine">{convertDashedDatetoDate(claim.dates.processed)}</span>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.dates.payment)}>Payment</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo u_preserveLine">{convertDashedDatetoDate(claim.dates.payment)}</span>
      </div>
      <div>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.claimNumber)}>Claim Number / Type</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">
          {' '}
          {claim.claimNumber} / {claim.product}
        </span>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.claimStatus)}>Claim Status</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">{claim.claimStatus}</span>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.tktk)}>Payment Type</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">{}</span>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.primaryICDDescription)}>ICD Description</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">{claim.primaryICDDescription}</span>
      </div>
      <div>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsHeader">Charged / Allowed</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">
          {formatCurrencyI18n(claim.totals.charge)} / {formatCurrencyI18n(claim.totals.allowed)}
        </span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsHeader">Paid</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">{formatCurrencyI18n(claim.totals.paid)}</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsHeader">
          Paid to
          <br />
          Patient / Provider
        </span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">
          {formatCurrencyI18n(claim.totals.paidToPatient)} / {formatCurrencyI18n(claim.totals.paidToProvider)}
        </span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsHeader">Copay / Deductible</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">
          {formatCurrencyI18n(claim.totals.copay)} / {formatCurrencyI18n(claim.totals.deductible)}
        </span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsHeader">
          Coinsurance /<br />
          Patient Responsibility
        </span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">
          {formatCurrencyI18n(claim.totals.coinsurance)} / {formatCurrencyI18n(claim.totals.patientResponsibility)}
        </span>
      </div>
      <div>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', providerAndOffice(claim))}>Provider / Office</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">{formatProviderAndOffice(claim)}</span>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.provider.specialty)}>Provider Speciality</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">{claim.provider.specialty}</span>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.provider.taxID)}>Provider TIN</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">{formatWithWarningsTIN(claim.provider.taxID)}</span>
        <span className={formatEmpty('fh-ZendeskMemberClaimRow_claimDetailsHeader', claim.networkName)}>Network Name</span>
        <span className="fh-ZendeskMemberClaimRow_claimDetailsInfo">{claim.networkName}</span>
      </div>
      <div className="fh-ZendeskMemberClaimRow_claimDetailsLinks">
        <a className="fh-ZendeskMemberClaimRow_claimDetailsLink" href={cleanLink(claim.links.patientEOB)} target="_blank" rel="noopener noreferrer">
          Member EOB
        </a>
        <a className="fh-ZendeskMemberClaimRow_claimDetailsLink" href={cleanLink(claim.links.providerEOP)} target="_blank" rel="noopener noreferrer">
          Provider EOP
        </a>
      </div>
    </div>
  )

  const claimNote = (x: ClaimNote) => {
    return (
      <div className="fh-ZendeskMemberClaimRow_note">
        <p className="fh-ZendeskMemberClaimRow_noteDates">
          <span className="fh-ZendeskMemberClaimRow_noteUser">{x.userID}</span>{' '}
          <span className="fh-ZendeskMemberClaimRow_noteCreated">Created: {formatDateTimeEasternWithFallback(x.createdTime)}</span>{' '}
          <span className="fh-ZendeskMemberClaimRow_noteUpdated">Updated: {formatDateTimeEasternWithFallback(x.updatedTime)}</span>
        </p>
        <p className="fh-ZendeskMemberClaimRow_noteText">{x.note}</p>
      </div>
    )
  }

  const claimNotes = (
    <div className="fh-ZendeskMemberClaimRow_notes" key={`${claim.claimNumber}-notes`}>
      <h2 className="fh-ZendeskMemberClaimRow_extrasHeader">Notes ({notes.length})</h2>
      {notes.map((x) => claimNote(x))}
    </div>
  )

  return (
    <div className={blockClass} role="presentation">
      {claimSummary}
      {isOpen ? claimDetails : null}
      {showNotes ? claimNotes : null}
    </div>
  )
}

export {ZendeskMemberClaimRow}
