import React from 'react'
import './ZendeskNewTicketSidebarMessage.scss'

const ZendeskNewTicketSidebarMessage = (props) => {
  const defaultMessage = (
    <>
      <p className="fh-Zendesk_sidebarText">
        To continue work on this ticket, please click the <span className="fh-Zendesk_highlightText">Submit as New</span> button below after completing the initial ticket details
      </p>
      <p className="fh-Zendesk_sidebarText">Member info will appear in this sidebar after submission</p>
    </>
  )
  const {message = defaultMessage} = props

  return (
    <div className="fh-ZendeskNewTicketSidebarMessage">
      <h2 className="fh-Zendesk_sidebarTitle">First add ticket info and submit</h2>
      {message}
    </div>
  )
}

export {ZendeskNewTicketSidebarMessage}
