import React, {useState} from 'react'
import classNames from 'classnames'
import './ZendeskAspect.scss'

/**
 * @name ZendeskAspect
 * @kind React Hook component
 *
 * Displays a formatted value with label, along with optional actions, like SSN show/hide.
 * For use with ZendeskAspectGroup.
 *
 * @param {Object}  props
 */
const ZendeskAspect = (props) => {
  const {format, formattedValue, title, type} = props
  const [isActive, setIsActive] = useState(true)
  const blockClassOptions = {
    'fh-ZendeskAspect': true,
    'fh-ZendeskAspect___groupStart': format === 'groupStart',
    'fh-ZendeskAspect___groupEnd': format === 'groupEnd',
    'fh-ZendeskAspect___toggleDisplay': format === 'toggleDisplay' && isActive
  }
  if (type) blockClassOptions[`fh-ZendeskAspect___${type}`] = true
  const blockClass = classNames(blockClassOptions)
  const toggleActive = () => setIsActive(!isActive)
  const clickHandler = format === 'toggleDisplay' ? toggleActive : undefined
  const aspectAction = format === 'toggleDisplay' ? <button className="fh-ZendeskAspect_action">{isActive ? 'show' : 'hide'}</button> : null

  return (
    <p className={blockClass} onClick={clickHandler}>
      <span className="fh-ZendeskAspect_label">{title}</span> <span className="fh-ZendeskAspect_value">{formattedValue}</span> {aspectAction}
    </p>
  )
}

export {ZendeskAspect}
