import React, {useState} from 'react'
import get from 'lodash.get'
import {BasicButton} from '@flumehealth/ui-react'
import {ZendeskMemberSearchForm} from './ZendeskMemberSearchForm'
import {ZendeskMemberSearchResults} from './ZendeskMemberSearchResults'
import './ZendeskMemberSearchInstructions.scss'

const ZendeskMemberSearchInstructions = (props) => {
  const {altHeader, locationType, setMember, setNewLinkId, ticketTags} = props
  const [members, setMembers] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const showInstructions = () => setIsOpen(true)
  const hideInstructions = () => setIsOpen(false)
  const isMemberTicket = ticketTags.includes('member')

  const handleMemberSearchResults = (data) => {
    const membersData = get(data, 'members', [])
    setMembers(membersData)
  }

  const ticketHeader = (
    <>
      <h2 className="fh-Zendesk_sidebarTitle">{altHeader || 'Add a Member'}</h2>
      <p className="fh-Zendesk_sidebarText fh-Zendesk_statementBlock">
        <span className="fh-Zendesk_highlightText">
          {isMemberTicket
            ? 'NOTE: Info will not display if the Zendesk Requester and Javelina record have not been merged'
            : 'NOTE: Info will not display if the Zendesk Member and Javelina record have not been merged'}
        </span>
      </p>
    </>
  )

  const ticketInstructions = (
    <>
      <h3 className="fh-Zendesk_sidebarTitle">Search Zendesk-based Members by a Requester's Name</h3>
      <p className="fh-Zendesk_sidebarText">
        Directly below the subject/title of this ticket you will see a date and other info. On that line, click the blue link labeled <span className="fh-Zendesk_boldText">(change)</span>. The{' '}
        <span className="fh-Zendesk_boldText">Requester</span> text field will become available in the left nav, where you can search Zendesk for a Member by that Member’s name. Suggested matches will
        appear as you type
      </p>
      <p className="fh-Zendesk_sidebarText">When you click a selection from those suggestions, that Member’s info will display here, in this sidebar</p>
      <h3 className="fh-Zendesk_sidebarTitle">Search Zendesk-based Members by Member ID</h3>
      <p className="fh-Zendesk_sidebarText">
        To open the search box, click the <span className="fh-Zendesk_boldText">magnifying glass icon</span> located in the top nav of this page
      </p>
      <p className="fh-Zendesk_sidebarText">
        Your search results will appear on a new page with 4 tabs: <span className="fh-Zendesk_boldText">Tickets</span>, <span className="fh-Zendesk_boldText">Articles</span>,{' '}
        <span className="fh-Zendesk_boldText">Users</span>, and <span className="fh-Zendesk_boldText">Organizations</span>. If one or more Members are found, you will see the number of matching
        Members included in the <span className="fh-Zendesk_boldText">Users</span> tab label, <span className="fh-Zendesk_highlightText">e.g. Users (2)</span> Click the{' '}
        <span className="fh-Zendesk_boldText">Users</span> tab to view the results, then click the related Member, which will take you to that Member’s Zendesk User page
      </p>
      <p className="fh-Zendesk_sidebarText">
        From that Zendesk User page, create a new ticket by clicking the <span className="fh-Zendesk_boldText">+ New Ticket</span> button which appears to the right of the Member’s name
      </p>
      <BasicButton clickHandler={hideInstructions} label="Back to Flume Member Search" />
    </>
  )

  const userHeader = (
    <>
      <h2 className="fh-Zendesk_sidebarTitle">{altHeader || 'Member Not Found'}</h2>
      <p className="fh-Zendesk_sidebarText fh-Zendesk_statementBlock">To find a Member, search Users by Member Name or Member ID</p>
      <p className="fh-Zendesk_sidebarText fh-Zendesk_statementBlock">
        <span className="fh-Zendesk_highlightText">NOTE: Info will not display if the Zendesk Requester and Javelina record have not been merged</span>
      </p>
    </>
  )

  const userInstructions = (
    <>
      <h3 className="fh-Zendesk_sidebarTitle">Search by Member Name or ID</h3>
      <p className="fh-Zendesk_sidebarText">
        To open the search box, click the <span className="fh-Zendesk_boldText">magnifying glass icon</span> located in the top nav of this page
      </p>
      <p className="fh-Zendesk_sidebarText">
        Your search results will appear on a new page with 4 tabs: <span className="fh-Zendesk_boldText">Tickets</span>, <span className="fh-Zendesk_boldText">Articles</span>,{' '}
        <span className="fh-Zendesk_boldText">Users</span>, and <span className="fh-Zendesk_boldText">Organizations</span>. If one or more Members are found, you will see the number of matching
        Members included in the <span className="fh-Zendesk_boldText">Users</span> tab label, <span className="fh-Zendesk_highlightText">e.g. Users (2)</span> Click the{' '}
        <span className="fh-Zendesk_boldText">Users</span> tab to view the results, then click the related Member, which will take you to that Member’s{' '}
        <span className="fh-Zendesk_boldText">User</span> page (this page)
      </p>
      <BasicButton clickHandler={hideInstructions} label="Back to Flume Member Search" />
    </>
  )

  const header = locationType.includes('user') ? userHeader : ticketHeader
  const instructions = locationType.includes('user') ? userInstructions : ticketInstructions

  const form = (
    <>
      <BasicButton clickHandler={showInstructions} label="How to search with Zendesk" />
      <div className="fh-Zendesk_headerBlock">
        <h2 className="fh-Zendesk_sidebarTitle fh-ZendeskMemberSearchInstructions_formTitle">{altHeader || 'Flume Member Search'}</h2>
        <p className="fh-Zendesk_sidebarText fh-Zendesk_statementBlock">{isMemberTicket ? 'Search Members by one or more criteria to find a Requester' : 'Search Members by one or more criteria'}</p>
        <ZendeskMemberSearchForm formSuccessHandler={handleMemberSearchResults} />
      </div>
    </>
  )

  const memberResults = (
    <div className="fh-ZendeskMemberSearchInstructions_results">
      <ZendeskMemberSearchResults locationType={locationType} members={members} setMember={setMember} setNewLinkId={setNewLinkId} />
      <BasicButton clickHandler={() => setMembers([])} title="Search Again" />
    </div>
  )

  return (
    <div className="fh-ZendeskMemberSearchInstructions">
      {header}
      {isOpen ? instructions : members.length > 0 ? memberResults : form}
    </div>
  )
}

export {ZendeskMemberSearchInstructions}
