/**
 * Accepts JavaScript Date compatible string, which is converted and formatted.
 * example: the string '2020-10-08T03:00:38Z' will return '10/07/2020, 23:00 EDT'
 * @param {string}  str     Any string that can be used to create a new JavaScript Date object
 * @return {string}
 */
const formatDateTimeEastern = (str: string) => {
  // @ts-ignore
  const options: DateTimeFormatOptions = {day: '2-digit', hour: '2-digit', hourCycle: 'h24', minute: '2-digit', month: '2-digit', timeZone: 'America/New_York', timeZoneName: 'short', year: 'numeric'}
  try {
    const date = new Date(str)
    return new Intl.DateTimeFormat('en-US', options).format(date)
  } catch (err) {
    // TODO - add 3rd party error tooling
    return 'Date Unavailable'
  }
}

/**
 * Accepts JavaScript Date compatible string or null value. If the provided value is a string
 * attempt to convert that string into a formatted date with Eastern time.
 * Otherwise return an en dash by default, unless a different replacement value is provided.
 * @param {null|string}   val                     null or any string that can be used to create a new JavaScript Date object
 * @param {string}        [emptyPlaceholder=–]    String to display when a null value is provided
 * @return {string}
 */
const formatDateTimeEasternWithFallback = (val: null | string, emptyPlaceholder: string = '–') => {
  return typeof val === 'string' ? formatDateTimeEastern(val) : emptyPlaceholder
}

export {formatDateTimeEastern, formatDateTimeEasternWithFallback}
