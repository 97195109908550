import React, {useContext, useEffect, useState} from 'react'
import cloneDeep from 'lodash.clonedeep'
import get from 'lodash.get'
import {BasicButton, ComposableForm, LeftRail, ModalContext, Pagination, StatusMessage, useAxios} from '@flumehealth/ui-react'
import {useGlobals} from '../../context'
import {ResultsHeader} from '../results/ResultsHeader'
import {ResultsList} from '../results/ResultsList'
import {ResultsFooter} from '../results/ResultsFooter'
import {LinkList} from '../nav/LinkList'
import {addAlarmsConfig} from '../../data/forms/addAlarmsConfig'
import {editAlarmsConfig} from '../../data/forms/editAlarmsConfig'
import {SpinnerScaleout} from '../spinners/SpinnerScaleout'
import './AlarmsPage.scss'

const AlarmsPage = () => {
  const {apiHostname, apiProtocol, errors} = useGlobals()
  const requestURL = `${apiProtocol}://${apiHostname}/v1/alarm`
  const [componentStatus, setComponentStatus] = useState(null)
  const [alarms, setAlarms, apiError, requestStatus] = useAxios({url: requestURL}, componentStatus)
  const [statusMessageVisible, setStatusMessageVisible] = useState(false)
  const [composableAddForm] = useState(addAlarmsConfig)
  const [composableEditForm] = useState(editAlarmsConfig)
  const {hideModal, showModal} = useContext(ModalContext)
  const apiErrorCode = get(apiError, 'status', null)
  const keyLabels = [
    {key: 'id', label: 'ID'},
    {key: 'path', label: 'Path'},
    {key: 'matcher', label: 'Matcher'},
    {key: 'duration', label: 'Interval'},
    {key: 'emails', label: 'Emails'},
    {key: 'bucket', label: 'Bucket'},
    {key: 'active', label: 'Active'}
  ]
  const keys = keyLabels.map((item) => item.key)

  if (apiErrorCode === 403 && componentStatus !== 403) {
    setComponentStatus(apiErrorCode)
  }

  const modalSuccessHandler = () => {
    // cleanup prior operations for fast user actions
    if (statusMessageVisible) {
      setStatusMessageVisible(false)
    }
    setStatusMessageVisible(true)
  }

  const sortIDAsc = (item1, item2) => {
    return item1.id - item2.id
  }

  const results: any[] = alarms && Array.isArray(alarms.alarms) ? [...alarms.alarms].sort(sortIDAsc) : []
  const modalAddFormConfig = cloneDeep(composableAddForm)
  const modalEditFormConfig = cloneDeep(composableEditForm)
  const addAlarmComposable = <ComposableForm action="create" config={modalAddFormConfig} endpoint={requestURL} formSuccessHandler={modalSuccessHandler} />
  const submissionMessage: any = statusMessageVisible ? <StatusMessage message="Your changes have been saved" fadeAfter={3000} setVisibility={setStatusMessageVisible} /> : null
  const errorMessage: any = apiError ? (
    <StatusMessage
      message={apiErrorCode && errors[apiErrorCode] ? errors[apiErrorCode] : 'Please refresh your browser to continue'}
      fadeAfter={10000}
      setVisibility={setStatusMessageVisible}
      status="error"
    />
  ) : null
  const pages = [{link: '/alarms', label: 'Absence Alarms List'}]

  const resultsPerPage = Math.min(results.length, 100)
  const pagination = <Pagination currentLocation={1} resultsPerPage={resultsPerPage} totalResults={results.length} />

  useEffect(() => {
    if (statusMessageVisible) {
      hideModal()
      setAlarms(null)
    }
  }, [statusMessageVisible])

  const pageContent = (
    <>
      <ResultsHeader title="Alarms" pagination={pagination}>
        <BasicButton label="Add Alarm" clickHandler={() => showModal(addAlarmComposable)} secondary />
      </ResultsHeader>
      <ResultsList
        actions={['delete', 'edit']}
        baseURL={`${apiProtocol}://${apiHostname}/v1/alarm`}
        deleteButtonLabel="Delete Alarm"
        deleteTitle="Delete Alarm"
        editFormConfig={modalEditFormConfig}
        apiErrorCode={apiErrorCode}
        keyLabels={keyLabels}
        keys={keys}
        modalSuccessHandler={modalSuccessHandler}
        requestStatus={requestStatus}
        rows={results}
      />
      <ResultsFooter pagination={pagination} />
    </>
  )

  return (
    <div className="AlarmsPage">
      {errorMessage || submissionMessage}
      <LeftRail>
        <LinkList links={pages} title="Alarms" />
      </LeftRail>
      {apiErrorCode === 403 ? <ResultsHeader title="Alarms" /> : requestStatus === 'response' ? pageContent : <SpinnerScaleout />}
    </div>
  )
}

export {AlarmsPage}
