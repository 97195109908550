import React, {useContext, useEffect, useRef, useState} from 'react'
import cloneDeep from 'lodash.clonedeep'
import get from 'lodash.get'
import {BasicButton, ComposableForm, createDisplayLabel, LeftRail, ModalContext, Pagination, StatusMessage, useAxios} from '@flumehealth/ui-react'
import {ResultsHeader} from '../results/ResultsHeader'
import {ResultsFilter} from '../results/ResultsFilter'
import {ResultsFooter} from '../results/ResultsFooter'
import {ResultsList} from '../results/ResultsList'
import {LinkList} from '../nav/LinkList'
import {useGlobals} from '../../context'
import {addGroupConfig} from '../../data/forms/addGroupConfig'
import {editGroupConfig} from '../../data/forms/editGroupConfig'
import {SpinnerScaleout} from '../spinners/SpinnerScaleout'
import './ReportListingsPage.scss'

const ReportListingsPage = () => {
  type SortFuncType = (a: any, b: any) => number
  const {apiHostname, apiProtocol, errors} = useGlobals()
  const requestURL = `${apiProtocol}://${apiHostname}/v1/reports/options`
  const [componentStatus, setComponentStatus] = useState(null)
  const [reportForms] = useAxios({url: `${apiProtocol}://${apiHostname}/v1/reports`}, componentStatus)
  const [reports, setReports, apiError, requestStatus] = useAxios({url: requestURL}, componentStatus)
  const [statusMessageVisible, setStatusMessageVisible] = useState(false)
  const [filteredRows, setFilteredRows] = useState<any[]>([])
  const {hideModal, showModal} = useContext(ModalContext)
  const resultsFilterRef = useRef()
  const apiErrorCode = get(apiError, 'status', null)
  const keyLabels = [
    {key: 'id', label: 'ID'},
    {key: 'entityID', label: 'Entity ID'},
    {key: 'type', label: 'Report Type'},
    {key: 'emails', label: 'Emails'},
    {key: 'options', label: 'Options'}
  ]
  const keys = keyLabels.map((item) => item.key)
  let composableAddForm = cloneDeep(addGroupConfig)
  let composableEditForm = cloneDeep(editGroupConfig)
  let reportTypeOptions = []

  if (apiErrorCode && componentStatus === null) {
    setComponentStatus(apiErrorCode)
  }

  const modalSuccessHandler = () => {
    // cleanup prior operations for fast user actions
    if (statusMessageVisible) {
      setStatusMessageVisible(false)
    }
    setStatusMessageVisible(true)
  }

  const sortTypeAsc = (a: any, b: any): number => {
    return `${a.type}`.localeCompare(b.type)
  }

  const sortEntityIDAsc = (a: any, b: any): number => {
    return `${a.entityID}`.localeCompare(b.entityID)
  }

  // order by Report Type, then Entity ID
  const defaultSort = (items): any[] => {
    // add sort functions in order of scope
    // e.g. if each sorted group is sorted by `entityID`
    // sort `entityID` first then compared `types` will fall into place
    const sortFns: SortFuncType[] = [sortEntityIDAsc, sortTypeAsc]
    const sortedItems = [...items]

    sortFns.forEach((fn) => {
      sortedItems.sort(fn)
    })

    return sortedItems
  }

  const toggleSortConfig = {
    // add states in usage order
    sortOrderStates: ['desc', 'asc', null],
    maxActive: 1,
    selectionHistory: [], // array of column names to reference if `maxActive` is greater than 1
    columns: [
      // Map type
      [
        'id',
        {
          valueType: 'number', // or 'string'
          sortOrder: 0 // optional, for explicitly starting the sort with this state, e.g. `desc` from `sortOrderStates`
        }
      ],
      [
        'entityID',
        {
          valueType: 'string', // or 'number'
          sortOrder: 0
        }
      ],
      [
        'type',
        {
          valueType: 'string', // or 'number'
          sortOrder: 0
        }
      ],
      [
        'emails',
        {
          valueType: 'string', // or 'number'
          sortOrder: 0
        }
      ]
    ]
  }

  const results: any[] = reports && Array.isArray(reports.options) ? defaultSort([...reports.options]) : []

  useEffect(() => {
    // preserve filters and update results
    // after an action, like adding a new Report
    if (reports && statusMessageVisible) {
      // eslint-disable-next-line
      // @ts-ignore
      resultsFilterRef.current.filter()
    } else if (reports) {
      setFilteredRows(results)
    }
  }, [reports])

  // add a single `condition` to each nested form component
  // condition based on option type
  if (reportForms) {
    const conditionalFields: any[] = []
    if (reportForms && reportForms.reports) {
      reportForms.reports.forEach((option) => {
        if (option.form && option.form.fields) {
          const condition = {
            target: '2',
            operator: '=',
            value: option.type,
            combinator: 'or'
          }
          option.form.fields.forEach((field) => {
            const conditions = [{...condition}]
            // `returnDataKey` flag for name of value to return
            const fieldClone = {
              returnDataKey: 'options',
              returnDataType: 'composableReturnArray',
              conditions,
              ...cloneDeep(field)
            }
            conditionalFields.push(fieldClone)
          })
        }
      })
    }

    const updatedAddFields = addGroupConfig.fields.length ? cloneDeep(addGroupConfig.fields) : []
    const updatedEditFields = editGroupConfig.fields.length ? cloneDeep(editGroupConfig.fields) : []
    updatedAddFields.splice(-1, 0, ...conditionalFields)
    updatedEditFields.splice(-1, 0, ...conditionalFields)
    composableAddForm = {fields: [...updatedAddFields]}
    composableEditForm = {fields: [...updatedEditFields]}
  }

  // dynamic report type options
  if (reportForms) {
    const apiReports = get(reportForms, 'reports', null)

    if (apiReports) {
      const defaultOption = {title: 'Report Type', value: ''}
      const types = apiReports.map((item) => item.type).sort()

      const options = types.map((item) => ({title: createDisplayLabel(item), value: item}))
      // add default
      options.unshift(defaultOption)
      reportTypeOptions = options

      // add options to form configs
      for (const item of addGroupConfig.fields) {
        if (item.formComponentID === 'type') {
          item['options'] = options
          break
        }
      }

      for (const item of editGroupConfig.fields) {
        if (item.formComponentID === 'type') {
          item['options'] = options
          break
        }
      }
    }
  }

  const addGroupComposable = <ComposableForm action="create" config={composableAddForm} endpoint={requestURL} formSuccessHandler={modalSuccessHandler} />
  const submissionMessage: any = statusMessageVisible ? <StatusMessage message="Your changes have been saved" fadeAfter={3000} setVisibility={setStatusMessageVisible} /> : null
  const errorMessage: any = apiError ? (
    <StatusMessage
      message={apiErrorCode && errors[apiErrorCode] ? errors[apiErrorCode] : 'Please refresh your browser to continue'}
      fadeAfter={10000}
      setVisibility={setStatusMessageVisible}
      status="error"
    />
  ) : null
  const pages = [
    {link: '/reports', label: 'Report Options Groups'},
    {link: '/run-reports', label: 'Run Reports'}
  ]
  const resultsPerPage = Math.min(filteredRows.length, 1000)
  const pagination = <Pagination currentLocation={1} resultsPerPage={resultsPerPage} totalResults={results.length} />

  useEffect(() => {
    if (statusMessageVisible) {
      hideModal()
      // refresh results unless in error state
      if (apiErrorCode === null) setReports(null)
    }
  }, [statusMessageVisible])

  const pageContent = (
    <>
      <ResultsHeader title="Report Options Groups" pagination={pagination}>
        <BasicButton label="Add Group" clickHandler={() => showModal(addGroupComposable)} secondary />
      </ResultsHeader>
      <ResultsFilter externalRef={resultsFilterRef} filterCallback={setFilteredRows} reportTypeOptions={reportTypeOptions} rows={results} />
      <ResultsList
        actions={['delete', 'edit']}
        baseURL={`${apiProtocol}://${apiHostname}/v1/reports/options`}
        defaultSort={defaultSort}
        deleteButtonLabel="Delete Report"
        deleteTitle="Delete Report"
        editFormConfig={composableEditForm}
        keyLabels={keyLabels}
        keys={keys}
        modalSuccessHandler={modalSuccessHandler}
        requestStatus={requestStatus}
        rows={filteredRows || results}
        toggleSortConfig={toggleSortConfig}
      />
      <ResultsFooter pagination={pagination} />
    </>
  )

  return (
    <div className="ReportListingsPage">
      {errorMessage || submissionMessage}
      <LeftRail>
        <LinkList links={pages} title="Reports" />
      </LeftRail>
      {apiErrorCode === 403 ? <ResultsHeader title="Report Options Groups" /> : requestStatus === 'response' ? pageContent : <SpinnerScaleout />}
    </div>
  )
}

export {ReportListingsPage}
