const MemberSearchConfig = {
  fields: [
    {
      cssClasses: [''],
      formComponentID: 'lastName',
      formComponentType: 'text',
      placeholder: 'Last Name',
      title: 'Last Name',
      validation: {
        required: false
      }
    },
    {
      cssClasses: [''],
      formComponentID: 'firstName',
      formComponentType: 'text',
      placeholder: 'First Name',
      title: 'First Name',
      validation: {
        required: false
      }
    },
    {
      cssClasses: [''],
      formComponentID: 'memberID',
      formComponentType: 'text',
      placeholder: 'Member ID',
      title: 'Member ID',
      validation: {
        required: false
      }
    },
    {
      cssClasses: [''],
      formComponentID: 'submitButton',
      formComponentType: 'submitButton',
      title: 'Search'
    }
  ]
}

export {MemberSearchConfig}
