const componentsByTypeReferenceConfig = {
  fields: [
    {
      "formComponentID": "formTitle",
      "formComponentType": "SectionTitle",
      "title": "Reference: Form Components by Type",
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "secondaryText1",
      "formComponentType": "SecondaryText",
      "title": "Flume products are built with a focus on composability and reuse to effectively support a wide range of applications and users.",
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "secondaryText2",
      "formComponentType": "SecondaryText",
      "title": "Components in this application leverage a shared UI library,  which is available to all Flume applications implemented in React. New components and changes published to the shared UI library will propagate to all instances of the library and its components, on that application's UI library version update.",
      "cssClasses": [
        "u_fullWidth",
        "SecondaryText___alternateStyle"
      ]
    },
    {
      "formComponentID": "subsectionTitle3",
      "formComponentType": "SubsectionTitle",
      "title": "Searchable",
      "cssClasses": [
        "SubsectionTitle___emphasis",
        "u_fullWidth"
      ]
    },
    {
      "formComponentID":"searchableClient",
      "formComponentType":"Searchable",
      "title": "Searchable Clients",
      "description": "Search one or more times to select one or more Clients",
      "componentOptions": {
        "dataPath": "/v1/clients/autocomplete?search=",
      },
      "options": [
        {"title": "Option 1", "value": "1"},
        {"title": "Option 2", "value": "2"},
        {"title": "Option 3", "value": "3"},
        {"title": "Option 4", "value": "4"}
      ],
      "validation": {
        "required": true
        // "min"
        // "max"
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID":"searchableVendor",
      "formComponentType":"Searchable",
      "title": "Searchable Vendors",
      "description": "Search one or more times to select one or more Vendors",
      "componentOptions": {
        "dataPath": "/v1/vendors/autocomplete?search=",
      },
      "options": [
        {"title": "Option 1", "value": "1"},
        {"title": "Option 2", "value": "2"},
        {"title": "Option 3", "value": "3"},
        {"title": "Option 4", "value": "4"}
      ],
      "validation": {
        "required": true
        // "min"
        // "max"
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID":"searchableVendorAlt",
      "formComponentType":"Searchable",
      "title": "Alternate Style Searchable Vendors",
      "description": "Search one or more times to select one or more Vendors",
      "componentOptions": {
        "dataPath": "/v1/vendors/autocomplete?search=",
      },
      "options": [
        {"title": "Option 1", "value": "1"},
        {"title": "Option 2", "value": "2"},
        {"title": "Option 3", "value": "3"},
        {"title": "Option 4", "value": "4"}
      ],
      "validation": {
        "required": true
        // "min"
        // "max"
      },
      "cssClasses": [
        "u_fullWidth",
        "Searchable___alternateStyle"
      ]
    },
    {
      "formComponentID": "subsectionTitle4",
      "formComponentType": "SubsectionTitle",
      "title": "Suggestable",
      "cssClasses": [
        "SubsectionTitle___emphasis",
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "suggestableClients",
      "formComponentType": "Suggestable",
      "title": "Suggestable Clients",
      "description": "Find a client",
      "componentOptions": {
        "dataPath": "/v1/clients/autocomplete?search=",
      },
      "options": [
        {"title": "Option 1", "value": "1"},
        {"title": "Option 2", "value": "2"},
        {"title": "Option 3", "value": "3"},
        {"title": "Option 4", "value": "4"}
      ],
      "placeholder": "Search by term",
      "validation": {
        "required": true
        // "min"
        // "max"
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "suggestableMemberFirstName",
      "formComponentType": "Suggestable",
      "title": "Suggestable Member First Name",
      "description": "Find member by first name",
      "optionsFormatConfig": {resultsContainer: 'members', title: 'employeeFirst', value: 'employeeFirst'},
      "componentOptions": {
        // https://api-dev.flumehealth.com/v1/members:search?firstName=felix
        "dataPath": "/v1/members:search?firstName=",
      },
      "options": [],
      "placeholder": "Search by term",
      "validation": {
        "required": true
        // "min"
        // "max"
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "suggestableMemberFirstNameAlt",
      "formComponentType": "Suggestable",
      "title": "Alternate Style Suggestable Member First Name",
      "description": "Find member by first name",
      "optionsFormatConfig": {resultsContainer: 'members', title: 'employeeFirst', value: 'employeeFirst'},
      "componentOptions": {
        // https://api-dev.flumehealth.com/v1/members:search?firstName=felix
        "dataPath": "/v1/members:search?firstName=",
      },
      "options": [],
      "placeholder": "Search by term",
      "validation": {
        "required": true
        // "min"
        // "max"
      },
      "cssClasses": [
        "u_fullWidth",
        "Suggestable___alternateStyle"
      ]
    },
    {
      "formComponentID": "subsectionTitle1",
      "formComponentType": "SubsectionTitle",
      "title": "Text-based Inputs",
      "cssClasses": [
        "SubsectionTitle___emphasis",
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "email",
      "formComponentType": "Email",
      "title": "Email",
      "description": "Text input for a single email address that by default validates for general email format",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "emailAlt",
      "formComponentType": "Email",
      "title": "Alternate Style Email",
      "description": "Text input for a single email address that by default validates for general email format",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Input___alternateStyle"
      ]
    },
    {
      "formComponentID": "mmddyyyy",
      "formComponentType": "MMDDYYYY",
      "title": "MMDDYYYY",
      "description": "Text input for an eight digit date with slashes. Reformats input and helps users by prepending leading `0`s for single digit months and days",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "mmddyyyyAlt",
      "formComponentType": "MMDDYYYY",
      "title": "Alternate Style MMDDYYYY",
      "description": "Text input for an eight digit date with slashes. Reformats input and helps users by prepending leading `0`s for single digit months and days",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Input___alternateStyle"
      ]
    },
    {
      "formComponentID": "numeric",
      "formComponentType": "Numeric",
      "title": "Numeric",
      "description": "Text input for number-based data. Restricts keyboard characters to appropriate content and supports up/down keys for adjusting values",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "numericAlt",
      "formComponentType": "Numeric",
      "title": "Alternate Style Numeric",
      "description": "Text input for number-based data. Restricts keyboard characters to appropriate content and supports up/down keys for adjusting values",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Input___alternateStyle"
      ]
    },
    {
      "formComponentID": "password",
      "formComponentType": "Password",
      "title": "Password",
      "description": "Standard entry field for user generated passwords. By default requires minimum 8 character length, one or more lowercase letters, one or more uppercase letters, one or more numbers, and one or more special characters",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "passwordAlt",
      "formComponentType": "Password",
      "title": "Alternate Style Password",
      "description": "Standard entry field for user generated passwords. By default requires minimum 8 character length, one or more lowercase letters, one or more uppercase letters, one or more numbers, and one or more special characters",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Input___alternateStyle"
      ]
    },
    {
      "formComponentID": "phone",
      "formComponentType": "Phone",
      "title": "Phone",
      "description": "Handles input formatting and US phone validation",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "phoneAlt",
      "formComponentType": "Phone",
      "title": "Alternate Style Phone",
      "description": "Handles input formatting and US phone validation",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Input___alternateStyle"
      ]
    },
    {
      "formComponentID": "ssn",
      "formComponentType": "SSN",
      "title": "SSN",
      "description": "Please enter your full Social Security Number",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "ssnAlt",
      "formComponentType": "SSN",
      "title": "Alternate Style SSN",
      "description": "Please enter your full Social Security Number",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Input___alternateStyle"
      ]
    },
    {
      "formComponentID": "text",
      "formComponentType": "Text",
      "title": "Text",
      "description": "This Text input is required",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "textAlt",
      "formComponentType": "Text",
      "title": "Alternate Style Text",
      "description": "This Text input is required",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Input___alternateStyle"
      ]
    },
    {
      "formComponentID": "yyyy",
      "formComponentType": "YYYY",
      "title": "YYYY",
      "description": "Enter a 4 digit year",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "yyyyAlt",
      "formComponentType": "YYYY",
      "title": "Alternate Style YYYY",
      "description": "Enter a 4 digit year",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Input___alternateStyle"
      ]
    },
    {
      "formComponentID": "zipCode",
      "formComponentType": "ZipCode",
      "title": "Zip Code",
      "description": "Enter a 5 or 9 digit US Zip Code",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "zipCodeAlt",
      "formComponentType": "ZipCode",
      "title": "Alternate Style Zip Code",
      "description": "Enter a 5 or 9 digit US Zip Code",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Input___alternateStyle"
      ]
    },
    {
      "formComponentID": "subsectionTitle2",
      "formComponentType": "SubsectionTitle",
      "title": "Textareas",
      "cssClasses": [
        "SubsectionTitle___emphasis",
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "textarea",
      "formComponentType": "Textarea",
      "title": "Textarea",
      "description": "Enter a whole mess of text here",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "textareaAlt",
      "formComponentType": "Textarea",
      "title": "Alternate Style Textarea",
      "description": "Enter a whole mess of text here",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Textarea___alternateStyle"
      ]
    },
    /////////// Searchable goes here after dev
    {
      "formComponentID": "subsectionTitle4",
      "formComponentType": "SubsectionTitle",
      "title": "Checkboxes, Dropdowns, Radios and Toggles",
      "cssClasses": [
        "SubsectionTitle___emphasis",
        "u_fullWith"
      ]
    },
    {
      "formComponentID": "checkboxesSingleItem",
      "formComponentType": "Checkboxes",
      "title": "Single Item Checkboxes",
      "description": "A description for a single item Checkboxes",
      "validation": {
        "required": true
      },
      "options": [
        {
          "title": "Option 1",
          "value": "value 1"
        }
      ],
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "checkboxesSingleItemAlt",
      "formComponentType": "Checkboxes",
      "title": "Alternate Style Single Item Checkboxes",
      "description": "A description for a single item Checkboxes",
      "validation": {
        "required": true
      },
      "options": [
        {
          "title": "Option 1",
          "value": "value 1"
        }
      ],
      "cssClasses": [
        "u_fullWidth",
        "Checkboxes___alternateStyle"
      ]
    },
    {
      "formComponentID": "checkboxesMultiItem",
      "formComponentType": "Checkboxes",
      "title": "Multi Item Checkboxes",
      "description": "A description for a multi item Checkboxes",
      "validation": {
        "required": true
      },
      "options": [
        {
          "title": "Option 1",
          "value": "value 1"
        },
        {
          "title": "Option 2",
          "value": "value 2"
        },
        {
          "title": "Option 3",
          "value": "value 3"
        },
        {
          "title": "Option 4",
          "value": "value 4"
        }
      ],
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "checkboxesMultiItemAlt",
      "formComponentType": "Checkboxes",
      "title": "Alternate Style Multi Item Checkboxes",
      "description": "A description for a multi item Checkboxes",
      "validation": {
        "required": true
      },
      "options": [
        {
          "title": "Option 1",
          "value": "value 1"
        },
        {
          "title": "Option 2",
          "value": "value 2"
        },
        {
          "title": "Option 3",
          "value": "value 3"
        },
        {
          "title": "Option 4",
          "value": "value 4"
        }
      ],
      "cssClasses": [
        "u_fullWidth",
        "Checkboxes___alternateStyle"
      ]
    },
    {
      "formComponentID": "dropdown",
      "formComponentType": "Dropdown",
      "title": "Dropdown",
      "description": "A description for a Dropdown",
      "validation": {
        "required": true
      },
      "options": [
        {
          "title": "Select Option",
          "value": ""
        },
        {
          "title": "Option 1",
          "value": "value 1"
        },
        {
          "title": "Option 2",
          "value": "value 2"
        },
        {
          "title": "Option 3",
          "value": "value 3"
        },
        {
          "title": "Option 4",
          "value": "value 4"
        }
      ],
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "dropdownAlt",
      "formComponentType": "Dropdown",
      "title": "Alternate Style Dropdown",
      "description": "A description for a Dropdown",
      "validation": {
        "required": true
      },
      "options": [
        {
          "title": "Select Option",
          "value": ""
        },
        {
          "title": "Option 1",
          "value": "value 1"
        },
        {
          "title": "Option 2",
          "value": "value 2"
        },
        {
          "title": "Option 3",
          "value": "value 3"
        },
        {
          "title": "Option 4",
          "value": "value 4"
        }
      ],
      "cssClasses": [
        "u_fullWidth",
        "Dropdown___alternateStyle"
      ]
    },
    {
      "formComponentID": "radios",
      "formComponentType": "Radios",
      "title": "Radios",
      "description": "A description for a set of Radio options",
      "validation": {
        "required": true
      },
      "options": [
        {
          "title": "Option 1",
          "value": "value 1"
        },
        {
          "title": "Option 2",
          "value": "value 2"
        },
        {
          "title": "Option 3",
          "value": "value 3"
        },
        {
          "title": "Option 4",
          "value": "value 4"
        }
      ],
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "radiosAlt",
      "formComponentType": "Radios",
      "title": "Alternate Style Radios",
      "description": "A description for a set of Radio options",
      "validation": {
        "required": true
      },
      "options": [
        {
          "title": "Option 1",
          "value": "value 1"
        },
        {
          "title": "Option 2",
          "value": "value 2"
        },
        {
          "title": "Option 3",
          "value": "value 3"
        },
        {
          "title": "Option 4",
          "value": "value 4"
        }
      ],
      "cssClasses": [
        "u_fullWidth",
        "Radios___alternateStyle"
      ]
    },
    {
      "formComponentID": "toggle",
      "formComponentType": "Toggle",
      "title": "Toggle",
      "description": "A checkbox style Toggle",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "toggleAlt",
      "formComponentType": "Toggle",
      "title": "Alternate Style Toggle",
      "description": "A checkbox style Toggle",
      "validation": {
        "required": true
      },
      "cssClasses": [
        "u_fullWidth",
        "Toggle___alternateStyle"
      ]
    },
    {
      "formComponentID": "subsectionTitle5",
      "formComponentType": "SubsectionTitle",
      "title": "Buttons",
      "cssClasses": [
        "SubsectionTitle___emphasis",
        "u_fullWidth"
      ]
    },
    {
      "formComponentID": "actionButton",
      "formComponentType": "ActionButton",
      "description": "Description for Action Button",
      "title": "Action Button",
      "cssClasses": [
        'u_fullWidth'
      ]
    },
    {
      "formComponentID": "actionButtonAlt",
      "formComponentType": "ActionButton",
      "description": "Description for Alternate Style Action Button",
      "title": "Alternate Style Action Button",
      "cssClasses": [
        'u_fullWidth',
        'Button___alternateStyle'
      ]
    },
    {
      "formComponentID": "cancelButton",
      "formComponentType": "CancelButton",
      "description": "Cancel Button description",
      "title": "Cancel Button",
      "cssClasses": [
        'u_fullWidth'
      ]
    },
    {
      "formComponentID": "cancelButtonAlt",
      "formComponentType": "CancelButton",
      "description": "Cancel Button description",
      "title": "Alternate Style Cancel Button",
      "cssClasses": [
        'u_fullWidth',
        'Button___alternateStyle'
      ]
    },
    {
      "formComponentID": "reviewButton",
      "formComponentType": "ReviewButton",
      "description": "Review Button description",
      "title": "Review Button",
      "cssClasses": [
        'u_fullWidth'
      ]
    },
    {
      "formComponentID": "reviewButtonAlt",
      "formComponentType": "ReviewButton",
      "description": "Review Button description",
      "title": "Alternate Style Review Button",
      "cssClasses": [
        'u_fullWidth',
        'Button___alternateStyle'
      ]
    },
    {
      "formComponentID": "submitButton",
      "formComponentType": "SubmitButton",
      "description": "Submit Button description",
      "title": "Submit Button",
      "cssClasses": [
        'u_fullWidth'
      ]
    },
    {
      "formComponentID": "submitButtonAlt",
      "formComponentType": "SubmitButton",
      "description": "Submit Button description",
      "title": "Alternate Style Submit Button",
      "cssClasses": [
        'u_fullWidth',
        'Button___alternateStyle'
      ]
    }
  ]
}

export {componentsByTypeReferenceConfig}
