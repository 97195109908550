/**
 * Create a Trestle-based Zendesk app in the Zendesk modal location.
 * Specify which Trestle modal component to use with `modalContentUrl`.
 * @param {Object}    zendeskClient     Zendesk Client responsible for calling the openModal() function
 * @param {Function}  setModalClient    React Hooks useState function for returning Zendesk Client created for the modal
 * @param {string}    modalContentUrl   Url for routing in the `ZendeskModals` component
 * @param {Object}    [options={}]      App-specific data provided to a new Zendesk Modal on initialization
 */
const openModal = (zendeskClient: any, setModalClient: Function, modalContentUrl, options = {}): void => {
  if (zendeskClient) {
    zendeskClient
      .invoke('instances.create', {
        location: 'modal',
        url: modalContentUrl,
        size: {
          // optional
          width: '800px',
          height: '80vh'
        }
      })
      .then((modalContext) => {
        const newModalClient = zendeskClient.instance(modalContext['instances.create'][0].instanceGuid)

        newModalClient.on('modal.close', () => {
          setModalClient(null)
        })

        newModalClient.on('instance.registered', () => {
          setModalClient(newModalClient)
        })

        // allow Zendesk instance registration to complete
        setTimeout(() => {
          newModalClient.trigger('fh_data', {action: 'initialData', parentInstanceGuid: zendeskClient._instanceGuid, options: options})
        }, 1000)
      })
      .catch((err) => {
        // TODO - add 3rd party error tooling
      })
  }
}

export {openModal}
