import React from 'react'
import {ComposableForm} from '@flumehealth/ui-react'
import {useGlobals} from '../../../context'
import {ClaimLookupConfig} from './ClaimLookupConfig'
import './ClaimLookup.scss'

const ClaimLookup = (props) => {
  const {apiHostname, apiProtocol} = useGlobals()
  const {formProcessingHandler, setClaimsData} = props
  const claimURL = `${apiProtocol}://${apiHostname}/v1/claims/:claimNumber`
  const claimData = (formResponseData) => {
    setClaimsData({claims: [formResponseData]})
  }

  return (
    <div className="fh-ClaimLookup">
      <ComposableForm
        action="retrieve"
        addQueryParams={false}
        config={ClaimLookupConfig}
        cssClasses={['fh-staticUnit']}
        endpoint={claimURL}
        formProcessingHandler={formProcessingHandler}
        formSuccessHandler={claimData}
        withCredentials
      />
    </div>
  )
}

export {ClaimLookup}
