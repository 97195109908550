import React from 'react'
import {FormatEmpty} from '../display/FormatEmpty'
import './AddressVertical.scss'

/**
 * Vertically formatted address information block.
 * @param {Object}  props
 * @param {string}  [props.address1]
 * @param {string}  [props.address2]
 * @param {string}  [props.address3]
 * @param {string}  [props.city]
 * @param {string}  [props.country]
 * @param {string}  [props.state]
 * @param {string}  [props.title='Address']
 * @param {string}  [props.zipCode]
 */
const AddressVertical = (props) => {
  const {address1, address2, address3, city, country, state, title = 'Address', zipCode} = props
  const formattedState = state ? state.toUpperCase() : state

  return (
    <div className="AddressVertical">
      <h2 className="AddressVertical_title">{title}</h2>
      <address className="AddressVertical_address">
        <p className="AddressVertical_element">
          <span className="AddressVertical_label">Address 1</span>
          <span className="AddressVertical_value">
            <FormatEmpty value={address1} />
          </span>
        </p>
        <p className="AddressVertical_element">
          <span className="AddressVertical_label">Address 2</span>
          <span className="AddressVertical_value">
            <FormatEmpty value={address2} />
          </span>
        </p>
        <p className="AddressVertical_element">
          <span className="AddressVertical_label">Address 3</span>
          <span className="AddressVertical_value">
            <FormatEmpty value={address3} />
          </span>
        </p>
        <p className="AddressVertical_element">
          <span className="AddressVertical_label">City</span>
          <span className="AddressVertical_value">
            <FormatEmpty value={city} />
          </span>
        </p>
        <p className="AddressVertical_element">
          <span className="AddressVertical_label">State</span>
          <span className="AddressVertical_value">
            <FormatEmpty value={formattedState} />
          </span>
        </p>
        <p className="AddressVertical_element">
          <span className="AddressVertical_label">ZIP Code</span>
          <span className="AddressVertical_value">
            <FormatEmpty value={zipCode} />
          </span>
        </p>
        <p className="AddressVertical_element">
          <span className="AddressVertical_label">Country</span>
          <span className="AddressVertical_value">
            <FormatEmpty value={country} />
          </span>
        </p>
      </address>
    </div>
  )
}

export {AddressVertical}
