import React, {useState} from 'react'
import classNames from 'classnames'
import get from 'lodash.get'
import {LeftRail, PaginationByToken} from '@flumehealth/ui-react'
import {useGlobals} from '../../../../context'
import {LinkList} from '../../../nav/LinkList'
import {MemberViews} from './MemberViews'
import {MemberSearch} from './MemberSearch'
import {MembersSearchResults} from './MembersSearchResults'
import {ResultsHeader} from '../../../results/ResultsHeader'
import './MemberSearchPage.scss'

const MemberSearchPage = () => {
  const {apiHostname, apiProtocol} = useGlobals()
  const memberSearchEndpoint: string = `${apiProtocol}://${apiHostname}/v1/members:search`
  const [members, setMembers] = useState<object[] | null>(null)
  const [requestQueryParams, setRequestQueryParams] = useState<object>({})
  const [hasMemberSearch, setHasMemberSearch] = useState<boolean>(false)
  const [nextPageToken, setNextPageToken] = useState<string>('')
  const [selectedMember, setSelectedMember] = useState<any>(null)
  const pages = [{link: '/member-search', label: 'Member Search'}]

  const resetMemberSearchData = () => {
    setMembers(null)
    setRequestQueryParams({})
    setNextPageToken('')
  }

  const memberSearchSuccessHandler = (data) => {
    const memberResults = get(data, 'members', null)
    const nextPageToken = get(data, 'nextPageToken', '')
    setMembers(memberResults)
    setNextPageToken(nextPageToken)
  }

  const memberSearchProcessingHandler = (isProcessing: boolean) => {
    setHasMemberSearch(isProcessing)
    if (isProcessing) {
      resetMemberSearchData()
    }
  }

  const viewMember = (data) => {
    setSelectedMember(data)
  }

  const contentStyles = classNames({
    MemberSearchPage_content: true,
    u_remove: selectedMember !== null
  })

  const memberView = selectedMember !== null ? <MemberViews member={selectedMember} setMember={setSelectedMember} /> : null

  const searchView = (
    <>
      <ResultsHeader title="Member Search" />
      <MemberSearch
        additionalQueryParams={{pageSize: '25'}}
        endpoint={memberSearchEndpoint}
        formProcessingHandler={memberSearchProcessingHandler}
        formSuccessHandler={memberSearchSuccessHandler}
        parentData={members}
        setParentData={setMembers}
        setRequestQueryParams={setRequestQueryParams}
      />
      <MembersSearchResults isUpdating={hasMemberSearch} searchResultsData={members} viewMember={viewMember} />
      {members === null ? null : (
        <PaginationByToken
          apiUrl={memberSearchEndpoint}
          parentResultsData={members}
          parentNextPageToken={nextPageToken}
          queryParams={{...requestQueryParams, pageSize: '25', nextPageToken: ''}}
          resultsCountKeyLocalStorage="membersResultsPageSize"
          setIsParentUpdating={setHasMemberSearch}
          setParentData={memberSearchSuccessHandler}
          setParentResultsData={setMembers}
          tokenKey="pageToken"
        />
      )}
    </>
  )

  return (
    <div className="MemberSearchPage">
      <LeftRail>
        <LinkList links={pages} title="Members" />
      </LeftRail>
      {selectedMember !== null ? memberView : null}
      <div className={contentStyles}>
        <ResultsHeader title="Member Search" />
        <MemberSearch
          additionalQueryParams={{pageSize: '25'}}
          endpoint={memberSearchEndpoint}
          formProcessingHandler={memberSearchProcessingHandler}
          formSuccessHandler={memberSearchSuccessHandler}
          parentData={members}
          setParentData={setMembers}
          setRequestQueryParams={setRequestQueryParams}
        />
        <MembersSearchResults isUpdating={hasMemberSearch} searchResultsData={members} viewMember={viewMember} />
        {members === null ? null : (
          <PaginationByToken
            apiUrl={memberSearchEndpoint}
            currentPageToken={2}
            parentResultsData={members}
            parentNextPageToken={nextPageToken}
            queryParams={{...requestQueryParams, pageSize: '25', nextPageToken: ''}}
            resultsCountKeyLocalStorage="membersResultsPageSize"
            setIsParentUpdating={setHasMemberSearch}
            setParentData={memberSearchSuccessHandler}
            setParentResultsData={setMembers}
            tokenKey="pageToken"
          />
        )}
      </div>
    </div>
  )
}

export {MemberSearchPage}
