const editAlarmsConfig = {
  fields: [
    {
      "formComponentID": "formTitle",
      "formComponentType": "SectionTitle",
      "title": "Edit Alarm"
    },
    {
      "formComponentID": "active",
      "formComponentType": "Toggle",
      "title": "Active",
      "validation": {
        "required": false
      },
    },
    {
      "formComponentID": "submitButton",
      "formComponentType": "SubmitButton",
      "title": ""
    }
  ]
}

export {editAlarmsConfig}
