import React from 'react'
import {formatCurrencyI18n} from '@flumehealth/ui-react'
import {ZendeskError} from '../../common/ZendeskError'

const AccumulatorNetworksTable = (props) => {
  const {data} = props

  // not ZendeskAspectGroup config, local for table-like display
  const accumulatorNetworksConfig = new Map([
    ['deductible', {title: 'Deductible', formattedValue: formatCurrencyI18n}],
    ['deductibleMax', {title: 'Deductible Max', formattedValue: formatCurrencyI18n}],
    ['outOfPocket', {title: 'Out of Pocket', formattedValue: formatCurrencyI18n}],
    ['outOfPocketMax', {title: 'Out of Pocket Max', formattedValue: formatCurrencyI18n}],
    ['outOfPocketCoinsPercent', {title: 'Out of Pocket Coins %'}],
    ['planPaid', {title: 'Plan Paid', formattedValue: formatCurrencyI18n}]
  ])
  const formattedNetworksRows: any[] = []

  try {
    accumulatorNetworksConfig.forEach((item, key) => {
      const inNetworkValue = item.formattedValue ? item.formattedValue(data.inNetwork[key]) : data.inNetwork[key]
      const outOfNetworkValue = item.formattedValue ? item.formattedValue(data.outOfNetwork[key]) : data.outOfNetwork[key]
      const formattedRow = (
        <React.Fragment key={`accum-${data.name}-${data.planYearAnniversary}-${key}-row`}>
          <span className="fh-ZendeskMemberAccumulator_tableRowLabel" key={`accum-${data.name}-${data.planYearAnniversary}-${key}`}>{item.title}</span>
          <span className="fh-ZendeskMemberAccumulator_tableCell" key={`accum-${data.name}-${data.planYearAnniversary}-${key}-in`}>{inNetworkValue}</span>
          <span className="fh-ZendeskMemberAccumulator_tableCell" key={`accum-${data.name}-${data.planYearAnniversary}-${key}-out`}>{outOfNetworkValue}</span>
        </React.Fragment>
      )
      formattedNetworksRows.push(formattedRow)
    })
  } catch (error) {
    return <ZendeskError errorOutput={[error]} />
  }

  return (
    <div className="fh-ZendeskMemberAccumulator_table" key={`accums-${data.planYearAnniversary}`}>
      <span className="fh-ZendeskMemberAccumulator_tableHeader" key={`accums-${data.name}-${data.planYearAnniversary}-empty`}></span>
      <span className="fh-ZendeskMemberAccumulator_tableHeader" key={`accums-${data.name}-${data.planYearAnniversary}-in`}>In Network</span>
      <span className="fh-ZendeskMemberAccumulator_tableHeader" key={`accums-${data.name}-${data.planYearAnniversary}-out`}>Out of Network</span>
      {formattedNetworksRows}
    </div>
  )
}

export {AccumulatorNetworksTable}
