import React, {useEffect, useState} from 'react'
import get from 'lodash.get'
import {PaginationByToken} from '@flumehealth/ui-react'
import {MemberClaimsResults} from './MemberClaimsResults'
import './MemberClaims.scss'

const MemberClaims = (props) => {
  const {claimsApi, claimsData, parentIsUpdating, preloadClaimsStatus, preloadNextPageToken = '', queryParams, setClaimsData} = props
  const [, setNextPageToken] = useState(preloadNextPageToken)
  const [isUpdating, setIsUpdating] = useState(parentIsUpdating)

  // display spinner based on preloading data
  useEffect(() => {
    setIsUpdating(parentIsUpdating)
  }, [parentIsUpdating])

  useEffect(() => {
    if (preloadClaimsStatus === 'error') {
      setIsUpdating(false)
    }
  }, [preloadClaimsStatus])

  // update display with pagination results
  const claimsSuccessHandler = (data) => {
    setClaimsData(data)
    setNextPageToken(get(data, 'nextPageToken', ''))
  }

  const setUpdatingStates = (bool) => {
    setIsUpdating(bool)
  }

  // reset PaginationByToken if `claimsData` is null
  return (
    <>
      <MemberClaimsResults claimsData={claimsData} isUpdating={isUpdating} claimsRequestStatus={preloadClaimsStatus} />
      {claimsData === null ? null : (
        <PaginationByToken
          apiUrl={claimsApi}
          parentNextPageToken={preloadNextPageToken}
          parentResultsData={claimsData}
          queryParams={queryParams}
          resultsCountKeyLocalStorage="memberClaimsResultsCount"
          setIsParentUpdating={setUpdatingStates}
          setParentData={claimsSuccessHandler}
          setParentResultsData={setClaimsData}
          tokenKey="pageToken"
        />
      )}
    </>
  )
}

export {MemberClaims}
