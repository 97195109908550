import React from 'react'
import {ComposableForm} from '@flumehealth/ui-react'
import {MemberSearchConfig} from './MemberSearchConfig'
import './MemberSearch.scss'

const MemberSearch = (props) => {
  const {additionalQueryParams, endpoint, formProcessingHandler, formSuccessHandler, setRequestQueryParams} = props

  return (
    <div className="MemberSearch">
      <p className="MemberSearch_example">Example — searching Last Name "S" with Member ID "100" will return all Members in Group 100 who have a last name starting with the letter "S"</p>
      <ComposableForm
        action="retrieve"
        additionalQueryParamPairs={additionalQueryParams}
        config={MemberSearchConfig}
        endpoint={endpoint}
        formProcessingHandler={formProcessingHandler}
        formSuccessHandler={formSuccessHandler}
        setRequestQueryParams={setRequestQueryParams}
        withCredentials
      />
    </div>
  )
}

export {MemberSearch}
