/* global document */
import React, {useEffect, useImperativeHandle} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {Dropdown, SubsectionTitle, Text} from '@flumehealth/ui-react'
import './ResultsFilter.scss'

const ResultsFilter = (props) => {
  const {externalRef, filterCallback, reportTypeOptions, rows} = props
  const methods = useForm()
  const {watch} = methods

  const escapeRegex = (str: string) => {
    return str.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  const email = watch('email') ? escapeRegex(watch('email')) : ''
  const entityID = watch('entityID') ? escapeRegex(watch('entityID')) : ''
  const reportType = watch('reportType') ? watch('reportType') : ''

  useImperativeHandle(externalRef, () => ({
    filter: () => filterRows()
  }))

  useEffect(() => {
    const input: HTMLInputElement | null = document.querySelector('#input-entityid')
    if (input) input.focus()
  }, [])

  // eslint-disable-next-line no-console
  const onSubmit = (data) => console.log('Trestle onSubmit data', data)

  const facets: Function[] = []

  if (entityID) {
    const entityIDRegex = new RegExp(`^${entityID}`, 'i')
    facets.push((item) => entityIDRegex.test(item.entityID))
  }

  if (email) {
    const emailRegex = new RegExp(`.*${email}.*`, 'i')
    facets.push((item) => item.emails.some((itemEmail) => emailRegex.test(itemEmail)))
  }

  if (reportType) {
    facets.push((item) => reportType === item.type)
  }

  const applyFacets = () => {
    let facetedRows = [...rows]

    // eslint-disable-next-line no-restricted-syntax
    for (const facet of facets) {
      // using `for of` to allow early termination
      if (facetedRows.length === 0) break
      facetedRows = facetedRows.filter((item) => facet(item))
    }

    return facetedRows
  }

  const filterRows = () => {
    const facetedRows = facets.length > 0 ? applyFacets() : rows
    if (filterCallback) filterCallback(facetedRows)
  }

  return (
    <div className="ResultsFilter">
      <FormProvider {...methods}>
        <SubsectionTitle name="filters" status={[]} title="Filters" passedContext={methods} />
        <form className="ResultsFilter_form" method="post" onSubmit={methods.handleSubmit(onSubmit)}>
          <Text keyUpHandler={() => filterRows()} name="entityID" passedContext={methods} placeholder="Entity ID" />
          <Text keyUpHandler={() => filterRows()} name="email" passedContext={methods} placeholder="Email" />
          <Dropdown changeHandler={() => filterRows()} name="reportType" options={reportTypeOptions} passedContext={methods} />
        </form>
      </FormProvider>
    </div>
  )
}

export {ResultsFilter}
