import React from 'react'
import {getArrayNameAndIndexFromString, isEmptyObj} from '@flumehealth/ui-react'
import {ZendeskAspect} from './ZendeskAspect'

/**
 * @name ZendeskAspectGroup
 * @kind React Hook component
 *
 * Render a collection of ZendeskAspect components using a provided config and related data.
 * Items appearing in the config that do not have a corresponding value are displayed as `empty items`.
 *
 * @param {Object}  props
 */
const ZendeskAspectGroup = (props) => {
  const {config, data} = props
  // global ignores
  const ignoreEmptyItems = ['address 2', 'address 3']
  const displayEmptyItem = (item) => !ignoreEmptyItems.includes(item.title.toLowerCase())
  const displayItems: any[] = []
  const emptyItems: any[] = []

  const getValueFromDescendant = (data, keys): any => {
    if (data) {
      const [arrayName, arrayIndex] = getArrayNameAndIndexFromString(keys[0])
      if (arrayName && typeof arrayIndex !== null) {
        return data[arrayName][arrayIndex][keys[1]]
      } else if (typeof data[keys[0]] !== 'undefined') {
        return data[keys[0]][keys[1]]
      }
    }
    return null
  }

  if (!isEmptyObj(data)) {
    config.forEach((item, key) => {
      // TODO – flexibly support relationships beyond parent/child
      // split key on `.`, if length greater than 1 then process as parent / child
      const keys = key.split('.')
      const value = keys.length === 1 ? data[key] : getValueFromDescendant(data, keys)

      if (value) {
        const formattedValue = item.formattedValue ? item.formattedValue(value) : value
        displayItems.push(<ZendeskAspect format={item.format} formattedValue={formattedValue} key={item.title} title={item.title} type={item.type} />)
      } else if (displayEmptyItem(item)) {
        emptyItems.push(item.title)
      }
    })
  }

  return (
    <>
      {displayItems}
      {emptyItems.length > 0 ? <ZendeskAspect format="groupStart" formattedValue={emptyItems.join(', ')} title="Missing Info" /> : null}
    </>
  )
}

export {ZendeskAspectGroup}
