import React from 'react'
import {Link} from 'react-router-dom'
import './LinkList.scss'

const LinkList = (props) => {
  const {links, title} = props
  const header = title ? <h2 className="LinkList_title">{title}</h2> : null
  const populatedLinks: any[] = links.map((item) => (
    <Link className="LinkList_link" key={`link-${item.label}`} to={item.link}>
      {item.label}
    </Link>
  ))

  return (
    <div className="LinkList">
      {header}
      {populatedLinks}
    </div>
  )
}

export {LinkList}
