const editGroupConfig = {
  fields: [
    {
      "formComponentID": "formTitle",
      "formComponentType": "SectionTitle",
      "title": "Edit Report"
    },
    {
      "formComponentID": "entityID",
      "formComponentType": "Text",
      "title": "Entity ID",
      "validation": {
        "required": true
      },
    },
    {
      "formComponentID": "type",
      "formComponentType": "Dropdown",
      "title": "Report Type",
      "options": [
        {
          "title": "Please Select",
          "value": ""
        },
        {
          "title": "Client Statement",
          "value": "client-statement"
        },
        {
          "title": "Foo Bar",
          "value": "foo-bar"
        },
        {
          "title": "Funding Notification MM",
          "value": "funding-notification-mm"
        },
        {
          "title": "Funding Notification RX",
          "value": "funding-notification-rx"
        },
        {
          "title": "Vendor Statement",
          "value": "vendor-statement"
        }
      ],
      "validation": {
        "required": true
      }
    },
    {
      "formComponentID": "emails",
      "formComponentType": "Cloneable",
      "title": "Emails",
      "validation": {
        "required": false
      },
      "cloneTitle": "Emails",
      "cloneButtonLabel": "Add Email",
      "cssClasses": [
        'Cloneable___simple'
      ],
      "fields": [
        {
          "formComponentID": "email",
          "formComponentType": "Email",
          "title": "Email",
          "validation": {
            "required": true
          }
        }
      ]
    },
    // TODO – API integration
    // {
    //   "formComponentID": "reportOptions",
    //   "formComponentType": "Checkboxes",
    //   "title": "Options",
    //   "options": [
    //     {
    //       "title": "Include PHI",
    //       "value": "includePHI"
    //     }
    //   ],
    //   "validation": {
    //     "required": true
    //   }
    // },
    {
      "formComponentID": "submitButton",
      "formComponentType": "SubmitButton",
      "title": ""
    }
  ]
}

export {editGroupConfig}
