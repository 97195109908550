import {createContext} from 'react'

// context specific to Trestle Zendesk Apps
// provides a history stack for determining which view to display
// history stack applies to a single instance of Trestle in Zendesk
// instances are specific to a Zendesk app `location`, e.g. `ticket_sidebar`, `modal`, etc
// to save data for use outside of a single Zendeks app location, send data to the `background` location
// in the future, embed `viewHistory` in a Map to provide support for more data in the context
const ZendeskAppContext = createContext({viewHistory: [], setViewHistory: null})

export {ZendeskAppContext}
