import React, {useState} from 'react'
import {BasicButton} from '@flumehealth/ui-react'
import './ZendeskHipaaMembers.scss'

/**
 * Display the Member access information one Member has for other Members.
 * Specific to the Zendesk Requester, who is a Member (not a Provider) and the HIPAA rights that requesting Member holds in Javelina.
 * @param {Object}      props
 * @param {Object[]}    props.hipaaClearedMembers       Array of preformatted Member details for display
 * @param {string}      props.primaryMemberName         First and last name of the requesting Member
 * @param {Function}    props.setActiveMember           State-based function for updating the member on view
 */
const ZendeskHipaaMembers = (props) => {
  const {hipaaClearedMembers = [], primaryMemberName, setActiveMember} = props
  const [showClearedMembers, setShowClearedMembers] = useState(false)

  const toggleClearedMembersView = () => {
    setShowClearedMembers(!showClearedMembers)
  }

  const clearedMembersView = hipaaClearedMembers.map((member) => <BasicButton clickHandler={() => setActiveMember(member.sequenceNum)} cssClasses={['u_linkButton']} title={member.name} />)
  const isDisabled = hipaaClearedMembers.length === 0 ? ' u_disabled' : ''
  const accessMessage =
    hipaaClearedMembers.length > 0
      ? `${primaryMemberName} has HIPAA Rights to view the following members (${hipaaClearedMembers.length})`
      : `${primaryMemberName} does not have HIPAA Rights to view other members`

  const clearedMembersOverview = (
    <div className="fh-ZendeskHipaaMembers_overview">
      <h2 className={`fh-ZendeskHipaaMembers_overviewHeader${isDisabled}`}>
        {accessMessage}
        {hipaaClearedMembers.length > 0 ? <BasicButton clickHandler={toggleClearedMembersView} cssClasses={['u_linkButton']} title={showClearedMembers ? 'hide' : 'view'} /> : null}
      </h2>
      {showClearedMembers ? <div className="fh-ZendeskHipaaMembers_clearedMembers">{clearedMembersView}</div> : null}
    </div>
  )

  return <div className="fh-ZendeskHipaaMembers">{clearedMembersOverview}</div>
}

export {ZendeskHipaaMembers}
