import React, {useState} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Footer} from '@flumehealth/ui-react'
// TODO - add back after cors fix for gcp_iap
// import get from 'lodash.get'
// import {formattedUsernameFromEmail, useAxios} from '@flumehealth/ui-react'
// import {useGlobals} from '../../context'
import {AlarmsPage} from './AlarmsPage'
import {MemberSearchPage} from './sections/members/MemberSearchPage'
import {ReportListingsPage} from './ReportListingsPage'
import {ReportsRunPage} from './ReportsRunPage'
import {ComponentsByTypeReferencePage} from './ComponentsByTypeReferencePage'
import {TopNav} from '../nav/TopNav'
import {ZendeskAuthed} from '../embed/zendesk/ZendeskAuthed'
import './TrestleAuth.scss'

const TrestleAuth = () => {
  const [userDisplayName] = useState(null)
  // TODO - add back after cors fix for gcp_iap
  // const {apiHostname} = useGlobals()
  // const gcpIapURL = `https://${apiHostname}/_gcp_iap/identity`
  // const [user] = useAxios(gcpIapURL)

  // useEffect(() => {
  //   const email = get(user, 'email', '')
  //   setUserDisplayName(formattedUsernameFromEmail(email))
  // }, [user])

  return (
    <>
      <Switch>
        <Route path="/return-to-zendesk">
          <ZendeskAuthed />
        </Route>
        <Route path="/">
          <TopNav userDisplayName={userDisplayName} />
          <div className="AppAuth">
            <Switch>
              <Route path="/alarms">
                <AlarmsPage />
              </Route>
              <Route path="/member-search">
                <MemberSearchPage />
              </Route>
              <Route path="/reports">
                <ReportListingsPage />
              </Route>
              <Route path="/run-reports">
                <ReportsRunPage />
              </Route>
              <Route path="/reference">
                <ComponentsByTypeReferencePage />
              </Route>
              <Route path="/">
                <Redirect to="/member-search" />
              </Route>
            </Switch>
          </div>
          <Footer />
        </Route>
      </Switch>
    </>
  )
}

export {TrestleAuth}
