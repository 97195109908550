/* global window */
import React from 'react'
import {extractHostname} from '../utils'

const defaultValues = {
  productType: 'MM',
  resultsCount: 100
}

const products = [
  {title: 'All', value: ''},
  {title: 'DE', value: 'DE'}, // Dental
  {title: 'FX', value: 'FX'}, // Flex
  {title: 'HRA', value: 'HRA'},
  {title: 'LT', value: 'LT'}, // Long-term Disability
  {title: 'MM', value: 'MM'},
  {title: 'RX', value: 'RX'},
  {title: 'ST', value: 'ST'}, // Short-term Disability
  {title: 'VI', value: 'VI'} // Vision
]

const quantity = [
  {title: '10', value: 10},
  {title: '20', value: 20},
  {title: '50', value: 50},
  {title: '100', value: 100}
]

const dropdowns = {products, quantity}

const zendeskDefaults = {
  errorHeight: '400px',
  errorWidth: '100%',
  sidebarHeight: '70vh',
  sidebarWidth: '100%'
}

const GlobalsContext = React.createContext({apiHostname: null, apiProtocol: null, appHostname: null, appProtocol: null, defaultValues, dropdowns, zendeskDefaults, errors: {}})

const GlobalsProvider = (props) => {
  const currentHostname = extractHostname(window.location.href)
  const envDetails = new Map([
    [
      'local',
      {
        apiHostname: 'api-staging.flumehealth.com',
        apiProtocol: 'https',
        appHostname: 'localhost:3000',
        appProtocol: 'http'
      }
    ],
    [
      'dev',
      {
        apiHostname: 'api-staging.flumehealth.com',
        apiProtocol: 'https',
        appHostname: 'trestle-dev.flumehealth.com',
        appProtocol: 'https'
      }
    ],
    [
      'staging',
      {
        apiHostname: 'api-staging.flumehealth.com',
        apiProtocol: 'https',
        appHostname: 'trestle-staging.flumehealth.com',
        appProtocol: 'https'
      }
    ],
    [
      'prod',
      {
        apiHostname: 'api.flumehealth.com',
        apiProtocol: 'https',
        appHostname: 'trestle.flumehealth.com',
        appProtocol: 'https'
      }
    ]
  ])

  const getEnvDetails = () => {
    switch (true) {
      case currentHostname === 'localhost':
        return envDetails.get('local')
      case currentHostname.indexOf('dev') > -1:
        return envDetails.get('dev')
      case /flume[0-9]+\.zendesk\.com/.test(currentHostname):
        return envDetails.get('dev')
      case currentHostname.indexOf('staging') > -1:
        return envDetails.get('staging')
      default:
        return envDetails.get('prod')
    }
  }

  const errors = {
    401: 'Please refresh this page to continue',
    403: 'You do not have access to this page'
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <GlobalsContext.Provider value={{...getEnvDetails(), defaultValues, dropdowns, zendeskDefaults, ...errors}} {...props} />
}

function useGlobals() {
  const context = React.useContext(GlobalsContext)
  return context
}

export {GlobalsProvider, useGlobals}
