import React from 'react'
import {convertDashedDatetoDate, formatPhone} from '@flumehealth/ui-react'
import {ZendeskAspectGroup} from '../ZendeskAspectGroup'

const MemberOverview = (props) => {
  const {data} = props

  // ZendeskAspectGroup config attributes
  // title            display title
  // [type]           hook for special cases, value can be anything
  // [format]         shared display and behavior options
  //                  values can be `groupStart`, `groupEnd`, `toggleDisplay`
  // [formattedValue] data formatting function
  const contactDisplayConfig = new Map([
    ['memberID', {title: 'Member Id'}],
    ['ssnSerialNum', {title: 'SSN', type: 'ssn', format: 'toggleDisplay'}],
    ['birthDate', {title: 'DOB', formattedValue: convertDashedDatetoDate}],
    ['gender', {title: 'Gender'}],
    ['relationship', {title: 'Relationship'}],
    ['coverages[0].eligibilitySince', {title: 'Effective Date', formattedValue: convertDashedDatetoDate}],
    ['client', {title: 'Client'}],
    ['location', {title: 'Location'}],
    ['clientID', {title: 'Client Id', format: 'groupEnd'}],
    ['emailAddress', {title: 'Email', format: 'groupStart'}],
    ['homePhone', {title: 'Home Phone', formattedValue: formatPhone}],
    ['mobilePhone', {title: 'Mobile Phone', format: 'groupEnd', formattedValue: formatPhone}],
    ['currentResidentialAddress.address1', {title: 'Address 1', format: 'groupStart'}],
    ['currentResidentialAddress.address2', {title: 'Address 2'}],
    ['currentResidentialAddress.address3', {title: 'Address 3'}],
    ['currentResidentialAddress.city', {title: 'City'}],
    ['currentResidentialAddress.state', {title: 'State', formattedValue: (str: string) => str.toUpperCase()}],
    ['currentResidentialAddress.postalCode', {title: 'Postal Code'}],
    ['currentResidentialAddress.country', {title: 'Country', format: 'groupEnd'}],
    ['mesaUsername', {title: 'Mesa User', format: 'groupStart'}]
  ])

  return <ZendeskAspectGroup config={contactDisplayConfig} data={data} />
}

export {MemberOverview}
