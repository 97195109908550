import React, {useEffect} from 'react'
import {BasicButton} from '@flumehealth/ui-react'
import {isZendeskEnv} from '../../../../utils'
import {useGlobals} from '../../../../context'
import './ZendeskErrorUnauthorized.scss'

const ZendeskErrorUnauthorized = (props) => {
  const {zendeskDefaults} = useGlobals()
  const {buttonText = 'OK', message = 'Unauthorized'} = props

  useEffect(() => {
    if (isZendeskEnv()) {
      // @ts-ignore
      const client = ZAFClient.init()

      if (client) {
        client.invoke('resize', {height: zendeskDefaults.errorHeight, width: zendeskDefaults.errorWidth})
      }
    }
  }, [])

  const refreshPage = () => window.location.reload()

  return (
    <div className="fh-ZendeskUnauthorized">
      <h2 className="fh-ZendeskUnauthorized_title">{message}</h2>
      <p className="fh-ZendeskUnauthorized_message">Some additional details on what this means and who to contact</p>
      <BasicButton cssClasses={['fh-Zendesk_primaryButtonAlt']} clickHandler={refreshPage} label={buttonText} />
    </div>
  )
}

export {ZendeskErrorUnauthorized}
