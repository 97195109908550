import React, {useEffect, useState} from 'react'
import {isZendeskEnv} from '../../../../utils'

const useZendeskClient = (init = null) => {
  const [zendeskClient, setZendeskClient] = useState<any | null>(null)

  useEffect(() => {
    if (isZendeskEnv()) {
      // @ts-ignore
      const client = ZAFClient.init()
      setZendeskClient(client)

      // @ts-ignore
      if (typeof init === 'function') init(client)
    }
  }, [])

  return [zendeskClient]
}

export {useZendeskClient}
