const addAlarmsConfig = {
  fields: [
    {
      "formComponentID": "formTitle",
      "formComponentType": "SectionTitle",
      "title": "Add Alarm"
    },
    {
      "formComponentID": "duration",
      "formComponentType": "Numeric",
      "title": "Check interval in minutes",
      "validation": {
        "required": true
      },
    },
    {
      "formComponentID": "matcher",
      "formComponentType": "Text",
      "title": "Regex for Filename Matching",
      "validation": {
        "required": true
      },
    },
    {
      "formComponentID": "path",
      "formComponentType": "Text",
      "title": "File Path",
      "validation": {
        "required": true
      },
    },
    {
      "formComponentID": "bucket",
      "formComponentType": "Text",
      "title": "Bucket",
      "validation": {
        "required": true
      },
      "value": "sftp-flume"
    },
    {
      "formComponentID": "emails",
      "formComponentType": "Cloneable",
      "title": "Recipient Emails",
      "validation": {
        "required": false
      },
      "cloneTitle": "Recipient Emails",
      "cloneButtonLabel": "Add Email",
      "cssClasses": [
        'Cloneable___simple'
      ],
      "fields": [
        {
          "formComponentID": "email",
          "formComponentType": "Email",
          "title": "Recipient Emails",
          "validation": {
            "required": true
          }
        }
      ]
    },
    {
      "formComponentID": "submitButton",
      "formComponentType": "SubmitButton",
      "title": ""
    }
  ]
}

export {addAlarmsConfig}
