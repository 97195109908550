import React from 'react'
import get from 'lodash.get'
import {convertDashedDatetoDate, EllipsisText, formatCurrencyI18n} from '@flumehealth/ui-react'
import {formattedProvider} from '../../../../utils'
import './MemberClaimsResultsItem.scss'

const MemberClaimsResultsItem = (props) => {
  const {claim = {}} = props
  const {claimNumber, claimStatus, dates, product, provider, totals} = claim

  const formatMissingData = (str: string): string | React.ReactNode => {
    return str ? str : <span className="MemberClaimsResultsItem_missingValue">—</span>
  }

  const incurred = get(dates, 'incurred', '')
  const incurredDate = incurred ? convertDashedDatetoDate(incurred) : formatMissingData(incurred)
  const payment = get(dates, 'payment', '')
  const paymentDate = payment ? convertDashedDatetoDate(payment) : formatMissingData(payment)
  const charge = formatCurrencyI18n(get(totals, 'charge', ''))
  const providerDisplay = formattedProvider(provider)

  return (
    <tr className="MembersClaimResultsItem">
      <td className="MemberClaimsResultsItem_incurred">{incurredDate}</td>
      <td className="MemberClaimsResultsItem_payment">{paymentDate}</td>
      <td className="MemberClaimsResultsItem_id">{claimNumber}</td>
      <td className="MemberClaimsResultsItem_type">{product}</td>
      <td className="MemberClaimsResultsItem_charge">${charge}</td>
      <td className="MemberClaimsResultsItem_provider">
        <EllipsisText>{providerDisplay}</EllipsisText>
      </td>
      <td className="MemberClaimsResultsItem_status">{claimStatus}</td>
    </tr>
  )
}

export {MemberClaimsResultsItem}
