import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import get from 'lodash.get'
import {AsciiError, BasicButton, Dropdown, encodeQueryData, getLocalStorageItem, PaginationByToken, setLocalStorageItem, ThinkingDot, useAxios} from '@flumehealth/ui-react'
import {isZendeskEnv, registerZendeskAppInstance, sendZendeskAppData} from '../../../../../utils'
import {useGlobals} from '../../../../../context'
import {ZendeskMemberClaimRow} from '../../common/ZendeskMemberClaimRow'
import {ClaimLookup} from '../../../../forms/claims/ClaimLookup'
import './ZendeskMemberClaims.scss'

/**
 * Zendesk modal app for displaying a collection of claims.
 * Does not directly make requests to the API. Presents data from a Zendesk ticket_sidebar, or stateful controller like PaginationByToken component.
 */
const ZendeskMemberClaims = () => {
  const {apiHostname, apiProtocol, dropdowns} = useGlobals()
  const methods = useForm()
  const [claimSearchData, setClaimSearchData] = useState(null)
  const [data, setData] = useState(null)
  const [memberId, setMemberId] = useState(null)
  const [memberPerson, setMemberPerson] = useState(null)
  const [memberName, setMemberName] = useState(null)
  const [hasClaimSearch, setHasClaimSearch] = useState(false)
  const [hasClaimSearchError, setHasClaimSearchError] = useState(false)
  const apiUrl = `${apiProtocol}://${apiHostname}/v1/members/${memberId}/people/${memberPerson}/claims`
  const [zendeskClient, setZendeskClient] = useState<any | null>(null)
  const [zendeskContext, setZendeskContext] = useState<any | null>(null)
  const [isUpdating, setIsUpdating] = useState(false)
  // TODO - move default claimsPageSize and claimsPageType values to globals
  const [claimsPageSize, setClaimsPageSize] = useState(getLocalStorageItem('claimsResultsPageSize', 100))
  const [claimsPageType, setClaimsPageType] = useState(getLocalStorageItem('claimsResultsPageType', 'MM'))
  const [nextPageToken, setNextPageToken] = useState<string>('')

  const queryParams = {
    pageSize: claimsPageSize,
    product: claimsPageType
  }

  // update claims data for dropdown type changes
  const claimsQueryParams = encodeQueryData(queryParams)
  const [dropdownClaimsRequestState, setDropdownClaimsRequestState] = useState<string | null>('hold')
  const [dropdownClaimsData, setDropdownClaimsData] = useAxios({url: `${apiUrl}${claimsQueryParams}`}, dropdownClaimsRequestState)

  // when the dropdown type updates, trigger 'refresh' for pagination
  const [paginationRequestState, setPaginationRequestState] = useState('hold')

  // set data changes triggered by dropdown type selection
  useEffect(() => {
    setData(dropdownClaimsData)
  }, [dropdownClaimsData])

  useEffect(() => {
    if (isZendeskEnv()) {
      // @ts-ignore
      const client = ZAFClient.init()
      setZendeskClient(client)

      if (client) {
        client.on('fh_data', (customEvent) => {
          if (customEvent.action === 'claimsData') {
            const nextPageTokenResult = get(customEvent, 'data.nextPageToken', '')
            setData(customEvent.data)
            setNextPageToken(nextPageTokenResult) // pagination
            // compound id e.g. `0000001234-0` for person 1 under member `0000001234`
            // 2 item array of memberId, followed by the person number
            setMemberId(customEvent.compoundMemberId[0])
            setMemberPerson(customEvent.compoundMemberId[1])
            setMemberName(customEvent.memberName)
          } else if (customEvent.action === 'error') {
            setData(customEvent.data)
          } else {
            // throw new Error(`Unknown customEvent action ${customEvent.action}`)
          }

          registerZendeskAppInstance(client, setZendeskContext)
        })
      }
    }
  }, [])

  // update local storage for claim/product type and trigger claims data reload for app in `ticket_sidebar`
  useEffect(() => {
    setLocalStorageItem('claimsResultsPageType', claimsPageType)
    if (zendeskClient) {
      // notify ticket_sidebar to reload cached claims
      sendZendeskAppData(zendeskClient, 'ticket_sidebar', {action: 'reloadClaims'})
    }
  }, [claimsPageType])

  // update local storage for results per page and trigger claims data reload for app in `ticket_sidebar`
  useEffect(() => {
    setLocalStorageItem('claimsResultsPageSize', claimsPageSize)
    if (zendeskClient) {
      // notify ticket_sidebar to reload cached claims
      sendZendeskAppData(zendeskClient, 'ticket_sidebar', {action: 'reloadClaims'})
    }
  }, [claimsPageSize])

  const resetClaimSearchData = () => {
    setClaimSearchData(null)
    setHasClaimSearchError(false)
  }

  const claimsPaginationSuccessHandler = (data) => {
    const nextPageTokenResult = get(data, 'nextPageToken', '')
    setData(data)
    setNextPageToken(nextPageTokenResult)
  }

  const claimSearchProcessingHandler = (isProcessing: boolean, resultsCount: number | null = null) => {
    setHasClaimSearch(isProcessing)
    if (isProcessing) {
      resetClaimSearchData()
    } else if (!isProcessing && resultsCount === 0) {
      setHasClaimSearchError(true)
    }
  }

  // claim type filters are specific to this claims view
  // however API requests are managed by PaginationByToken component
  // trigger refresh for PaginationByToken component
  const filterClaimsByType = (e) => {
    const type = get(e, 'target.value')
    setLocalStorageItem('claimsResultsPageType', type)
    setClaimsPageType(type)
    setData(null)
    setDropdownClaimsData(null)
    setDropdownClaimsRequestState(null)
    setPaginationRequestState('refresh')
    setNextPageToken('')
  }

  const formatClaimsData = (data, isExpanded = false) => {
    if (data && data.claims) {
      if (data.claims.length < 1) {
        const selectedProductValue = claimsPageType ? `${claimsPageType} ` : ''
        return (
          <p className="fh-ZendeskMemberClaims_notFound">
            <span className="fh-Zendesk_highlightText">No {selectedProductValue}claims found</span>
          </p>
        )
      }
      return data.claims.map((claim) => <ZendeskMemberClaimRow claim={claim} defaultNotes={isExpanded} defaultOpen={isExpanded} key={claim.claimNumber} />)
    } else {
      return null
    }
  }

  const placeholder = () => {
    return (
      <div className="fh-ZendeskMemberClaims_placeholder">
        <ThinkingDot />
      </div>
    )
  }

  const claimRows = data ? formatClaimsData(data, false) : placeholder()
  const claimSearchResults = claimSearchData ? formatClaimsData(claimSearchData, false) : placeholder()
  const updatingPlaceholder = hasClaimSearch || (isUpdating && data) ? placeholder() : null

  return data === null ? (
    placeholder()
  ) : data === 'error' ? (
    <AsciiError>
      <p>We were unable to find {claimsPageType} results for this member</p>
      <p>Please refresh this page and try again, or try another claim/product type</p>
    </AsciiError>
  ) : (
    <div className="fh-ZendeskMemberClaims">
      <header className="fh-ZendeskMemberClaims_header">
        <h1 className="fh-ZendeskMemberClaims_title">Claims{memberName ? ` for ${memberName}` : ''}</h1>
        <Dropdown
          changeHandler={(e) => filterClaimsByType(e)}
          className="fh-ZendeskMemberClaims_claimTypes"
          name="claimTypeDropdown"
          options={dropdowns.products}
          passedContext={methods}
          value={claimsPageType}
        />
        <ClaimLookup formProcessingHandler={claimSearchProcessingHandler} setClaimsData={setClaimSearchData} />
        <div className="fh-ZendeskMemberClaims_columnLabels">
          <p className="fh-ZendeskMemberClaims_columnLabelDate">Incurred</p>
          <p className="fh-ZendeskMemberClaims_columnLabelNumber">Claim Number / Type</p>
          <p className="fh-ZendeskMemberClaims_columnLabelCharge">Charge</p>
          <p className="fh-ZendeskMemberClaims_columnLabelStatus">Status</p>
          <p className="fh-ZendeskMemberClaims_columnLabelProvider">Provider / Office</p>
        </div>
      </header>

      {hasClaimSearchError ? (
        <div>
          <p>No claim found for that number</p>
          <BasicButton clickHandler={resetClaimSearchData} label="Back" />
        </div>
      ) : (
        <div className="fh-ZendeskMemberClaims_claimRows">
          {updatingPlaceholder}
          {claimSearchData ? claimSearchResults : claimRows}
        </div>
      )}
      {claimSearchData ? <BasicButton clickHandler={resetClaimSearchData} label="Browse all claims" /> : null}
      <PaginationByToken
        apiUrl={apiUrl}
        axiosRequestState={paginationRequestState}
        cssClasses={claimSearchData ? ['u_hide'] : []}
        itemsPerPageLabel="Claims per page"
        parentResultsData={data}
        parentNextPageToken={nextPageToken}
        queryParams={{...queryParams, pageSize: '20', nextPageToken: ''}}
        resultsCountKeyLocalStorage="claimsResultsPageSize"
        setIsParentUpdating={setIsUpdating}
        setParentData={claimsPaginationSuccessHandler}
        setParentResultsData={setData}
        tokenKey="pageToken"
      />
    </div>
  )
}

export {ZendeskMemberClaims}
