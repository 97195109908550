import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {LeftNavContext} from '@flumehealth/ui-react'
import {LogoFlumeTrestle} from '../branding/Logo'
import './TopNav.scss'
import IconProfile from './img/icon-profile.svg'

const TopNav = (props) => {
  const {hideMenu, userDisplayName} = props
  const {closeLeftNav, toggleLeftNav} = useContext(LeftNavContext)
  const leftNavItems = (
    <>
      {/*<Link className="LeftNav_item u_iconAlarm" to="/alarms" onClick={closeLeftNav}>*/}
      {/*  Alarms*/}
      {/*</Link>*/}
      <Link className="LeftNav_item u_iconMembers" to="/member-search" onClick={closeLeftNav}>
        Members
      </Link>
      <Link className="LeftNav_item u_iconReport" to="/reports" onClick={closeLeftNav}>
        Reports
      </Link>
    </>
  )
  const topNavUser = userDisplayName ? (
    <div className="TopNav_user">
      <img src={IconProfile} className="TopNav_userIcon" alt="User Profile" />
      <span className="TopNav_userName">{userDisplayName}</span>
    </div>
  ) : null

  return (
    <div className="TopNav">
      {hideMenu === true ? null : <button className="TopNav_menuButton" onClick={() => toggleLeftNav(leftNavItems)} />}
      <LogoFlumeTrestle />
      {topNavUser}
    </div>
  )
}

export {TopNav}
