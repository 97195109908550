import React from 'react'
import get from 'lodash.get'
import {ComposableForm} from '@flumehealth/ui-react'
import {memberLookupConfig} from './ZendeskMemberSearchFormConfig'
import {useGlobals} from '../../../../../context'
import './ZendeskMemberSearchForm.scss'

const ZendeskMemberSearchForm = (props) => {
  const {apiHostname, apiProtocol} = useGlobals()
  const {formSuccessHandler} = props
  const searchUrl = `${apiProtocol}://${apiHostname}/v1/members:search`

  // returns a message if no results found
  const noResults = (data): null | string => {
    const message = 'No members found for your search'
    const foundMembers = get(data, 'members', [])
    return foundMembers.length > 0 ? null : message
  }

  // formMessages can contain one or more messages
  // messages relate to various states
  const formMessages = {noResults}

  return (
    <div className="fh-ZendeskMemberSearchForm">
      <ComposableForm action="retrieve" config={memberLookupConfig} endpoint={searchUrl} formMessages={formMessages} formSuccessHandler={formSuccessHandler} withCredentials />
    </div>
  )
}

export {ZendeskMemberSearchForm}
