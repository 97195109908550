import React, {useEffect, useState} from 'react'
import axios from 'axios'
// import './App.scss'
import {useGlobals} from '../../context'
import {TrestleAuth} from './TrestleAuth'
import {TrestleLogin} from './TrestleLogin'

const Trestle = () => {
  const {apiHostname, apiProtocol, appHostname, appProtocol} = useGlobals()
  const [isAuthed, setIsAuthed] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)
  const [isWaiting, setIsWaiting] = useState(true)
  const verifyUrl = `${apiProtocol}://${apiHostname}/v1/login`
  const loginUrl = `${apiProtocol}://${apiHostname}/v1/login?redirect=${appProtocol}://${appHostname}`
  const pause = 1000 * 0.5

  const verifyAuth = async () => {
    const axiosConfig = {
      headers: {
        Accept: 'application/json'
      },
      withCredentials: true
    }

    setTimeout(() => {
      setIsAvailable(true)
    }, pause)

    await axios
      .get(verifyUrl, axiosConfig)
      .then((response) => {
        // api call has a small but jarring lag
        // make this more palatable with a sort pause
        if (response.status === 204) {
          setIsAuthed(true)
        } else {
          setIsAuthed(false)
          setIsWaiting(false)
        }
      })
      .catch((e) => {
        // TODO – replace with logging service

        // eslint-disable-next-line no-console
        console.log(e)
        // api call has a small but jarring lag
        // make this more palatable with a sort pause
        setIsAuthed(false)
        setIsWaiting(false)
      })
  }

  useEffect(() => {
    verifyAuth()
  }, [])

  return <>{isAuthed && isAvailable ? <TrestleAuth /> : <TrestleLogin isWaiting={isWaiting} loginURL={loginUrl} />}</>
}

export {Trestle}
