import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import classNames from 'classnames'
import get from 'lodash.get'
import {BasicButton, Dropdown, encodeQueryData, getLocalStorageItem, Radios, stripLeadingString, Tabs, ThinkingDot, useAxios, useAxiosAllSettled} from '@flumehealth/ui-react'
import {isZendeskEnv, openModal, registerZendeskAppInstance, zeroPaddedSequenceNumber} from '../../../../../utils'
import {useGlobals} from '../../../../../context'
import {AccumulatorOverviewYear} from '../../common/accumulators/AccumulatorOverviewYear'
import {MemberOverview} from '../../common/member/MemberOverview'
import {MemberCoverage} from '../../common/member/MemberCoverage'
import {ZendeskMemberSearchInstructions} from '../../common/member/ZendeskMemberSearchInstructions'
import {ZendeskLinkedItemGroup} from '../../common/ZendeskLinkedItemGroup'
import {ZendeskHipaaMembers} from './ZendeskHipaaMembers'
import './ZendeskMemberInfo.scss'
// local test data
// import {ZendeskLinkedItemsGroupSample} from '../../common/ZendeskLinkedItemsGroupData'
// import {memberClaimsData} from '../../../../../data/zendesk/memberClaimsData'
// import {memberAccumulatorData} from '../../../../../data/zendesk/memberAccumulatorData'

/**
 * Zendesk sidebar app for viewing details related to a Member and related Dependents.
 * Provides 3 tab-based views for 'Member Overview', 'Eligibility/Coverage' and 'Accumulators'.
 * Displays a list of other members the ticket requester (`primaryMemberKey`) has HIPAA rights to view.
 * Preloads `primaryMemberKey` claims for modal display.
 */
const ZendeskMemberInfo = () => {
  const {apiHostname, apiProtocol, appHostname, appProtocol, defaultValues, zendeskDefaults} = useGlobals()
  const methods = useForm()
  const membersURLBase = `${apiProtocol}://${apiHostname}/v1/members`
  const modalContentUrl = `${appProtocol}://${appHostname}`
  const modalUrlMemberClaims = `${modalContentUrl}/zendesk/member-claims`
  const modalUrlHipaaWarning = `${modalContentUrl}/zendesk/hippa-warning`
  const tabOptions = [{label: 'Overview'}, {label: 'Coverage'}, {label: 'Accumulators'}]
  const claimsPageType = getLocalStorageItem('claimsResultsPageType', defaultValues.productType)
  const claimsPageSize = getLocalStorageItem('claimsResultsPageSize', defaultValues.resultsCount)
  const claimsQueryParams = encodeQueryData({product: claimsPageType, pageSize: claimsPageSize})
  const [previousClaimsQueryParams, setPreviousClaimsQueryParams] = useState(claimsQueryParams)
  const [zendeskClient, setZendeskClient] = useState<any>(null)
  const [zendeskContext, setZendeskContext] = useState<any>(null)
  const [modalClient, setModalClient] = useState<any>(null) // Zendesk client for modal
  const [membersURL, setMembersURL] = useState<string | null>(null)
  const [claimsUrl, setClaimsUrl] = useState<string | null>(null)
  const [memberId, setMemberId] = useState<string | null>(null)
  const [memberSequenceNumber, setMemberSequenceNumber] = useState<string | null>(null)
  const [selectedMember, setSelectedMember] = useState('0') // should default to the member, not a dep
  const paddedSequenceNumber = memberSequenceNumber !== null ? zeroPaddedSequenceNumber(memberSequenceNumber) : '00'
  const [primaryMemberKey, setPrimaryMemberKey] = useState<string | null>(null) // Zendesk requester for ticket sidebar location, Zendesk user for user sidebar location
  const [primaryMemberName, setPrimaryMemberName] = useState<string | null>(null)
  const [hipaaClearedMembersForPrimaryMember, setHipaaClearedMembersForPrimaryMember] = useState<any[]>([])
  const [membersLookup, setMembersLookup] = useState(new Map())
  const [memberRequestState, setMemberRequestState] = useState<string | null>('hold') // hold for Zendesk `ticket.requester.externalId`
  const [membersData, setMembersData, membersDataError] = useAxios({url: membersURL}, memberRequestState)
  const [claimsRequestState, setClaimsRequestState] = useState<string | null>('hold')
  const [claimData, setClaimData, , claimDataStatus] = useAxios({url: `${claimsUrl}${claimsQueryParams}`}, claimsRequestState)
  // formatted accumulators data from API, uses string-based member sequence numbers as keys, and additional key 'family'
  const [accumulators, setAccumulators] = useState(new Map())
  const [accumulatorsRequestState, setAccumulatorsRequestState] = useState<string | null>('hold')
  const [accumulatorsUrls, setAccumulatorsUrls] = useState<string[]>([])
  const [accumulatorsData, setAccumulatorsData] = useAxiosAllSettled({urls: accumulatorsUrls, processName: 'memberInfoAccumulators'}, accumulatorsRequestState)
  const [isFamilyDisplay, setIsFamilyDisplay] = useState(false)
  const [viewState, setViewState] = useState('processing')
  const [selectedTab, setSelectedTab] = useState('Overview')
  const [zendeskLocationType, setZendeskLocationType] = useState(null)
  const [hasNewLink, setHasNewLink] = useState(true)
  const [newLinkId, setNewLinkId] = useState<string>('')
  const [ticketTags, setTicketTags] = useState<string[]>([])

  // add for Zendesk `background` location data
  // const [zendeskData, setZendeskData] = useState(null)

  // local test data
  // const claimData = memberClaimsData
  // const claimDataStatus = null // for use with test data `memberClaimsData`
  // const accumulatorData = memberAccumulatorData

  // styles
  const appLocationClass = classNames({
    'fh-Zendesk_supportTicketSidebar': true
  })
  const blockClass = classNames({
    'fh-Zendesk_memberInfo': true
  })

  // refresh preloaded claims if query params change
  if (claimsQueryParams !== previousClaimsQueryParams) {
    setClaimData(null)
    setClaimsRequestState(null)
    setPreviousClaimsQueryParams(claimsQueryParams)
  }

  // full set of HIPAA Coverages for a group of members
  const getHipaaCoverages = (coverages): Set<string> => {
    const hipaaCoverages = new Set<string>()

    coverages.forEach((coverage) => {
      const HIPAARightsHolders = coverage.HIPAARightsHolders !== null ? coverage.HIPAARightsHolders : []
      HIPAARightsHolders.forEach((compoundMemberId) => hipaaCoverages.add(compoundMemberId))
    })

    return hipaaCoverages
  }

  const getCompoundIdFromSequenceNum = (sequenceNum, overrideMemberId = '') => {
    const paddedSequenceNum = `${sequenceNum}`.padStart(2, '0')
    const id = overrideMemberId || memberId
    return `${id}-${paddedSequenceNum}`
  }

  // new member to view, replace member data, claims data, etc
  const setMember = (flumeCompoundId: string) => {
    const [flumeMemberId, flumeSequenceNum] = flumeCompoundId.split('-')
    if (flumeMemberId) {
      setViewState('processing')
      const strippedSequenceNum = stripLeadingString('0', flumeSequenceNum)
      setMemberId(flumeMemberId)
      setSelectedMember(strippedSequenceNum)
      setMemberSequenceNumber(strippedSequenceNum)
      setMembersURL(`${membersURLBase}/${flumeMemberId}`)
      setMembersData(null)
      setMemberRequestState(null)
      setClaimsUrl(`${membersURLBase}/${flumeMemberId}/people/${strippedSequenceNum}/claims`)
      setClaimData(null)
      setClaimsRequestState(null)
    }
  }

  // prep initial member view at a Zendesk app location
  const processMember = (data, key) => {
    // externalId includes sequence number segment after a dash, e.g. `001234567-00`
    const externalId = get(data, key, null)
    // TODO – revisit 'ELG' pattern, potentially use axios errors instead
    const isEligibilityUser = /^ELG-.*/.test(externalId)
    if (isEligibilityUser) {
      setViewState('eligibilityUser')
    } else if (externalId) {
      setPrimaryMemberKey(externalId)
      setMember(externalId)
    } else {
      setViewState('addRequester')
    }
  }

  const processMemberForZendeskTicketLocation = (client) => {
    client
      .get('ticket')
      .then((data) => {
        const ticketTagsData = get(data, 'ticket.tags', [])
        setTicketTags(ticketTagsData)
        processMember(data, 'ticket.requester.externalId')
      })
      .catch((err) => {
        // TODO - add 3rd party error tooling
      })
  }

  const processMemberForZendeskUserLocation = (client) => {
    client
      .get('user')
      .then((data) => processMember(data, 'user.externalId'))
      .catch((err) => {
        // TODO - add 3rd party error tooling
      })
  }

  const setMemberHipaaRights = (compoundMemberId, details, lookup) => {
    const memberProvidingRights = {
      name: details.name,
      sequenceNum: details.sequenceNum
    }

    details.hipaaCoverageIds.forEach((idWithRightsAccess) => {
      const memberWithRightsAccess = lookup.get(idWithRightsAccess)
      memberWithRightsAccess.hipaaClearedMembers.set(compoundMemberId, memberProvidingRights)
    })
  }

  // Zendesk client for ZendeskMemberInfo
  useEffect(() => {
    if (isZendeskEnv()) {
      // @ts-ignore
      const client = ZAFClient.init()
      setZendeskClient(client)

      if (client) {
        client.invoke('resize', {height: zendeskDefaults.sidebarHeight, width: zendeskDefaults.sidebarWidth})

        // TODO - uncomment when `background` location data needed
        // client.on('fh_data', (passedData) => {
        //   setZendeskData(passedData)
        // })

        client
          .context()
          .then((context) => {
            const locationName = get(context, 'location', '')
            const isUserLocation = locationName.includes('user')
            setZendeskLocationType(locationName)
            if (isUserLocation) {
              processMemberForZendeskUserLocation(client)
            } else {
              processMemberForZendeskTicketLocation(client)
            }
          })
          .catch((err) => {
            // TODO - add 3rd party error tooling
          })

        try {
          // Zendesk event for new requester
          client.on('ticket.requester.externalId.changed', (data) => {
            if (typeof data === 'string') {
              // restart app for sanitation
              window.location.reload()
            }
          })
        } catch (err) {
          // TODO - add 3rd party error tooling
        }

        try {
          // Flume custom event
          // listen for modal when user changes product type or results counts for claims view
          client.on('fh_data', (passedData) => {
            if (passedData.action === 'reloadClaims') {
              setClaimData(null)
              setClaimsRequestState(null)
            } else if (passedData.action === 'hippaOverride') {
              setActiveMemberDetails(passedData.memberId, passedData.viewMember)
            }
          })
        } catch (err) {
          // TODO - add 3rd party error tooling
        }

        registerZendeskAppInstance(client, setZendeskContext)
      }
    }
  }, [])

  // update view for selected member
  const setMemberLookupDetails = (member, lookup, hipaaCoverages) => {
    const lookupDetails = {
      name: `${member.first} ${member.last}`,
      compoundMemberId: member.compoundMemberId,
      sequenceNum: `${member.sequenceNum}`,
      hipaaCoverageIds: hipaaCoverages, // compound ids of members who have rights to this member
      hipaaClearedMembers: new Map() // display ready details of other members this member can access under HIPAA
    }

    lookup.set(member.compoundMemberId, lookupDetails)
  }

  const getMemberLookup = (): Map<string, object> => {
    const lookup = new Map()

    membersData.people.forEach((member) => {
      const coverages = member.coverages !== null ? member.coverages : []
      const hipaaCoverages = getHipaaCoverages(coverages)

      setMemberLookupDetails(member, lookup, hipaaCoverages)
    })

    lookup.forEach((details, compoundMemberId) => {
      setMemberHipaaRights(compoundMemberId, details, lookup)
    })

    return lookup
  }

  const setAccumulatorUrls = (membersData): void => {
    const urls: string[] = []

    membersData.people.forEach((member) => {
      const compoundMemberId = member.compoundMemberId || ''
      const [sharedMemberId, fullSequenceNumber] = compoundMemberId.split('-')
      const strippedSequenceNumber = stripLeadingString('0', fullSequenceNumber)

      if (sharedMemberId && fullSequenceNumber) {
        urls.push(`${membersURLBase}/${sharedMemberId}/people/${strippedSequenceNumber}/accumulators/medical`)
      }
    })

    urls.push(`${membersURLBase}/${memberId}/accumulators/medical`)

    setAccumulatorsUrls(urls)
    setAccumulatorsRequestState(null)
    setAccumulators(new Map())
    setAccumulatorsData(null)
  }

  const getHipaaClearedMembersForMember = (compoundId, lookup): object[] => {
    const activeMember = lookup.get(compoundId)
    if (activeMember) {
      const accessibleMembers = Array.from(activeMember.hipaaClearedMembers.values())
      return accessibleMembers.map((member: any) => ({...member}))
    }

    return []
  }

  const setDisplayNames = (lookup) => {
    const primaryMemberFromLookup = lookup.get(primaryMemberKey)
    const memberName = get(primaryMemberFromLookup, 'name', 'Member')
    setPrimaryMemberName(memberName)
  }

  const isActiveMemberHippaCleared = (sequenceNum) => {
    const activeMember = membersLookup.get(primaryMemberKey)
    const otherMemberCompoundId = getCompoundIdFromSequenceNum(sequenceNum)
    if (primaryMemberKey === otherMemberCompoundId) return true
    return typeof activeMember.hipaaClearedMembers.get(otherMemberCompoundId) !== 'undefined'
  }

  const getLookupMemberFromSequenceNum = (sequenceNum: string) => {
    const compoundId = getCompoundIdFromSequenceNum(sequenceNum)
    return membersLookup.get(compoundId)
  }

  const setActiveMemberDetails = (memberId: string, sequenceNum: string) => {
    setSelectedMember(sequenceNum)
    setMemberSequenceNumber(sequenceNum)
    setClaimsUrl(`${membersURLBase}/${memberId}/people/${sequenceNum}/claims`)
    setClaimData(null)
    setClaimsRequestState(null)
    setHasNewLink(true)
  }

  const setActiveMember = (sequenceNum: string) => {
    if (isActiveMemberHippaCleared(sequenceNum)) {
      if (memberId) {
        setActiveMemberDetails(memberId, sequenceNum)
      } else {
        // TODO - 3rd party reporting
      }
    } else {
      const lookupMember = getLookupMemberFromSequenceNum(sequenceNum)

      if (sequenceNum !== '') {
        const options = {
          viewMember: sequenceNum,
          accessorName: primaryMemberName,
          accesseeName: lookupMember ? lookupMember.name : 'Member',
          memberId
        }
        openModal(zendeskClient, setModalClient, modalUrlHipaaWarning, options)
      }
    }
  }

  // for dropdown select
  const selectActiveMember = (e) => {
    const sequenceNum: string = e.currentTarget.value
    setActiveMember(sequenceNum)
  }

  // set member lookup, hippa info, display names, etc for new API data
  useEffect(() => {
    if (membersData && membersData.people) {
      const lookup = getMemberLookup()
      setMembersLookup(lookup)
      setHipaaClearedMembersForPrimaryMember(getHipaaClearedMembersForMember(primaryMemberKey, lookup))
      setDisplayNames(lookup)
      setAccumulatorUrls(membersData)
    }
  }, [membersData])

  // on API error, set error view
  useEffect(() => {
    const errorMessage = get(membersDataError, 'message', '')
    if (errorMessage.includes('404') || membersDataError) {
      setViewState('memberNotFound')
    } else {
      setViewState('processing')
    }
  }, [membersDataError])

  // set `accumulators` data Map with member sequence ids as keys, and special key 'family'
  useEffect(() => {
    if (accumulatorsData) {
      const mapValues: [string, string][] = []
      accumulatorsData.forEach((resultItem, i) => {
        const accumulatorDataKey = membersData.people[i] ? membersData.people[i].sequenceNum : 'family'
        const accumulatorDataValue = resultItem.status === 'rejected' ? resultItem.reason : resultItem.value.accumulators
        // convert accumulatorDataKey to string to match HTML form control values
        mapValues.push([`${accumulatorDataKey}`, accumulatorDataValue])
      })

      setAccumulators(new Map(mapValues))
    }
  }, [accumulatorsData])

  // send cached claims to Zendesk client in modal location
  useEffect(() => {
    try {
      if (modalClient) {
        // `modalClient` not available at `instance.registered`, as described in Zendesk docs
        // a timeout is required for the new modal's client to be accessible
        setTimeout(() => {
          if (claimData) {
            modalClient.trigger('fh_data', {
              action: 'claimsData',
              compoundMemberId: [memberId, selectedMember],
              memberName: getMemberName(selectedMember),
              data: claimData
            })
          } else if (claimDataStatus === 'error') {
            modalClient.trigger('fh_data', {action: 'error'})
          }
        }, 1000)
      }
    } catch (err) {
      // TODO - add 3rd party error tooling
    }
  }, [claimData, claimDataStatus, modalClient])

  // pause useAxios requests
  useEffect(() => {
    setAccumulatorsRequestState('hold')
    setClaimsRequestState('hold')
  }, [accumulatorsRequestState, claimsRequestState])

  const getSharedInfo = (data: any) => {
    return data
      ? {
          client: `${data.clientName} – ${data.clientID}`,
          clientID: data.clientID,
          clientName: data.clientName,
          location: `${data.locationName} – ${data.locationID}`,
          memberID: data.memberID
        }
      : {}
  }

  const getMemberName = (sequenceNumber): string => {
    const member = get(membersData, `people[${sequenceNumber}]`, null)
    return member ? `${member.first} ${member.last}` : ''
  }

  const getSelectedMemberInfo = (i: string | null) => {
    const index = Number(i)
    const sharedInfo = getSharedInfo(membersData)
    const selectedMemberInfo = membersData ? membersData.people[index] : {}
    // use top level `memberID` from `sharedInfo` and delete individual `memberID`
    // individual `memberID` are empty and overwrite `sharedInfo`
    delete selectedMemberInfo.memberID
    return {...sharedInfo, ...selectedMemberInfo}
  }

  const getSelectedMemberCoverage = (i: string | null) => {
    const index = Number(i)
    const coverages = get(membersData, `people[${index}].coverages`, [])
    // display in descending order for eligibility begin date
    return [...coverages].reverse()
  }

  // formatted display items
  const selectedMemberCoverage = memberSequenceNumber ? getSelectedMemberCoverage(memberSequenceNumber) : null
  const membersDropdown = membersData !== null ? membersData.people.map((person) => ({title: `${person.first} ${person.last}`, value: `${person.sequenceNum}`})) : []
  const Overview = memberSequenceNumber && getSelectedMemberInfo(memberSequenceNumber) ? <MemberOverview data={getSelectedMemberInfo(memberSequenceNumber)} /> : null
  const Coverage = selectedMemberCoverage ? selectedMemberCoverage.map((coverageItem) => <MemberCoverage data={coverageItem} />) : null
  const accumulatorOptions = [
    {title: 'Individual', value: 'individual'},
    {title: 'Family', value: 'family'}
  ]

  const handleAccumulatorSelection = () => {
    setIsFamilyDisplay(!isFamilyDisplay)
  }

  const accumulatorSelector = (
    <Radios
      changeHandler={handleAccumulatorSelection}
      name="accumulatorView"
      options={accumulatorOptions}
      validation={{required: true}}
      passedContext={methods}
      title="View Accumulators For"
      value="individual"
    />
  )
  const selectedAccumulators = accumulators.size > 0 && isFamilyDisplay ? accumulators.get('family') : accumulators.size > 0 && memberSequenceNumber ? accumulators.get(memberSequenceNumber) : []
  const accumulatorTotals = accumulators.size > 0 ? <AccumulatorOverviewYear accumulatorData={selectedAccumulators} /> : [null, null]
  const Accumulators = (
    <>
      {accumulatorSelector}
      {accumulatorTotals}
    </>
  )
  const contentConfig = {Overview, Coverage, Accumulators}
  const selectedPanel = contentConfig[selectedTab]

  const showSearch = () => {
    setAccumulators(new Map())
    setViewState('search')
  }

  const placeholder = (
    <div className="fh-Zendesk_memberInfo_placeholder">
      <ThinkingDot />
    </div>
  )

  return (
    <div className={appLocationClass}>
      {!membersDataError && accumulators && accumulators.size > 0 ? (
        <>
          <Dropdown name="members" options={membersDropdown} changeHandler={selectActiveMember} passedContext={methods} valueControlled={memberSequenceNumber} />
          <ZendeskHipaaMembers hipaaClearedMembers={hipaaClearedMembersForPrimaryMember} primaryMemberName={primaryMemberName} setActiveMember={setActiveMember} />
          <BasicButton clickHandler={() => openModal(zendeskClient, setModalClient, modalUrlMemberClaims)} cssClasses={['fh-Zendesk_topLink']} label="View Claims" />
          <Tabs clickHandler={setSelectedTab} initialState={selectedTab} options={tabOptions} />
          <div className={blockClass}>{selectedPanel}</div>
          <BasicButton clickHandler={() => openModal(zendeskClient, setModalClient, modalUrlMemberClaims)} label="View Claims" />
          {memberId && paddedSequenceNumber ? (
            <ZendeskLinkedItemGroup
              hasNewLink={hasNewLink}
              id={`${memberId}-${paddedSequenceNumber}`}
              locationType={zendeskLocationType}
              refreshResultsForId={newLinkId}
              setHasNewLink={setHasNewLink}
              setMember={setMember}
              showSearch={showSearch}
              title="Links"
              zendeskClient={zendeskClient}
              zendeskContext={zendeskContext}
            />
          ) : null}
        </>
      ) : viewState === 'processing' ? (
        placeholder
      ) : (membersDataError || viewState === 'eligibilityUser') && zendeskLocationType === 'user_sidebar' ? (
        <p className="fh-Zendesk_sidebarText fh-Zendesk_statementBlock">
          <span className="fh-Zendesk_highlightText">NOTE: Flume tools are not currently available for this user</span>
        </p>
      ) : (
        <>
          <ZendeskMemberSearchInstructions
            altHeader={viewState === 'memberNotFound' ? 'Member Not Found' : null}
            locationType={zendeskLocationType}
            setMember={setMember}
            setNewLinkId={setNewLinkId}
            ticketTags={ticketTags}
          />
          <ZendeskLinkedItemGroup
            hasNewLink={hasNewLink}
            id={`${memberId}-${paddedSequenceNumber}`}
            locationType={zendeskLocationType}
            refreshResultsForId={newLinkId}
            setHasNewLink={setHasNewLink}
            setMember={setMember}
            title="Links"
            zendeskClient={zendeskClient}
            zendeskContext={zendeskContext}
          />
        </>
      )}
    </div>
  )
}

export {ZendeskMemberInfo}
