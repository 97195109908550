import React from 'react'
import {BasicButton} from '@flumehealth/ui-react'
import './ZendeskError.scss'

const ZendeskError = (props) => {
  const {children, error, errorOutput, message, resetErrorBoundary} = props
  const defaultMessage = (
    <>
      A problem occurred with Trestle
      <br />
      The team has been notified
    </>
  )

  const refreshPage = () => window.location.reload()

  const errors = Array.isArray(errorOutput) && errorOutput.length > 0 ? [...errorOutput] : [error]

  const formattedErrors = errors.map((error) => {
    const requestInfoMessage = error.config && error.config.method && error.config.url ? `${error.config.method.toUpperCase()} ${error.config.url}` : null

    return error.message || requestInfoMessage ? (
      <div className="fh-ZendeskError_errors">
        {error.message ? <p className="fh-ZendeskError_error">{error.message}</p> : null}
        {requestInfoMessage ? <p className="fh-ZendeskError_error">{requestInfoMessage}</p> : null}
      </div>
    ) : null
  })

  return (
    <div className="fh-ZendeskError">
      <BasicButton clickHandler={refreshPage} cssClasses={['fh-ZendeskError_refreshButton']} title="Refresh Page" />
      {/*<BasicButton clickHandler={resetErrorBoundary} cssClasses={['fh-ZendeskError_refreshButton']} title="Reset Error Boundary" />*/}
      {children ? children : <p className="fh-ZendeskError_message">{message ? message : defaultMessage}</p>}
      {formattedErrors}
    </div>
  )
}

export {ZendeskError}
