import get from 'lodash.get'
import axios, {AxiosRequestConfig} from 'axios'

/**
 * Formatted data object for saving a new ticket related Link for a user display.
 * @param {string}  ticketId     Zendesk ticket id
 * @param {string}  userId       Flume Member Id, Flume Provider Id or other Flume identifier
 */
const getMemberDisplayLinkData = (ticketId: string, userId: string) => {
  return {
    from: {
      id: ticketId,
      type: 'ticket'
    },
    note: '',
    source: 'memberDisplay',
    to: {
      id: userId,
      type: 'user'
    }
  }
}

/**
 * Save a new link to Flume's Zendesk links API.
 * @param {string}    apiUrl
 * @param {Object}    linkData
 * @param {Function}  callback
 */
const saveNewLink = (apiUrl: string, linkData: any, callback: Function | null = null) => {
  const requestConfig: AxiosRequestConfig = {
    method: 'post',
    url: apiUrl,
    data: linkData,
    withCredentials: true
  }

  axios(requestConfig)
    .then((response) => {
      const data = get(response, 'data', {})
      if (callback) callback(data)
    })
    .catch((err) => {
      // TODO - add 3rd party error reporting
      console.log(err)
    })
}

export {getMemberDisplayLinkData, saveNewLink}
